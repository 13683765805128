import { useSelector } from 'react-redux';
import CalendarIcon from './svg/CalendarIcon';
import { Col, Row } from 'react-bootstrap';
import DefaultScans from './DefaultScans';
import { getPeriodString } from '../../../hooks/helpers';

const PeriodScans = ({ scans, total }) => {
  const { periodStart, periodEnd } = useSelector((state) => ({
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd
  }));

  return (
    <Row>
      <Col>
        <div className="border-end">
          <div className="text-gray fs-7 d-flex align-items-center">
            <span className="me-2 pb-1">
              <CalendarIcon />
            </span>
            {getPeriodString(periodStart || 0, periodEnd)}
          </div>
          <div className="fw-bold fs-2">{scans}</div>
        </div>
      </Col>
      <Col>
        <DefaultScans scans={total} />
      </Col>
    </Row>
  );
};

export default PeriodScans;
