import classnames from 'classnames';
import { Spinner } from 'react-bootstrap';

const LoaderMePos = ({ toggle, opacity }) => {
  if (toggle) {
    return (
      <div
        className={classnames(
          'mt-0 position-fixed top-0 start-0 min-vw-100 min-vh-100 d-flex justify-content-center align-items-center',
          {
            [`bg-white  bg-opacity-${opacity}`]: !!opacity
          }
        )}
        style={{ zIndex: 490 }}
      >
        <Spinner animation="border" role="status" variant="violet-hover">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return null;
  }
};

export default LoaderMePos;
