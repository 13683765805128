import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  qrIds: [],
  location: '',
  device: '',
  isCompareMode: false,
  periodStart: moment().subtract(1, 'week'),
  periodEnd: moment(),
  secondPeriodStart: null,
  secondPeriodEnd: null,
  mode: 'default',
  isFitersActive: false,
  statisticType: 'DAILY_STATISTIC',
  master: 'all',
  service: 'all',
  haircutType: 'all',
  eventsArray: []
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(_, { payload }) {
      return payload;
    },
    resetFilters() {
      return initialState;
    }
  }
});
export const { setFilters, resetFilters } = filters.actions;
export default filters.reducer;
