import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../../elements/general';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const typesOfStatistic = [
  { key: 'POPULAR_SERVICE', labelKey: 'filters.popularityServices' },
  { key: 'POPULAR_MASTER', labelKey: 'filters.popularityMasters' },
  { key: 'COMPLETE_MASTER', labelKey: 'filters.completeMasters' },
  { key: 'COMPLETE_SERVICE', labelKey: 'filters.completeServices' }
];

const TypesFilter = ({ selectedType, setSelectedType }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const handleTypeSelect = (labelKey) => {
    setSelectedType(labelKey);
    setIsActive(true);
  };

  const translatedTypes = typesOfStatistic.map((item) => ({
    ...item,
    label: t(item.labelKey)
  }));

  return (
    <DropField className="custom-dropdown py-2 rounder-4px border">
      <Dropdown.Toggle className="form-select">
        {translatedTypes.find((item) => item.key === selectedType)?.label || t('statistic.daily')}
      </Dropdown.Toggle>

      <Dropdown.Menu className='custom-dropdown-menu'>
        <Dropdown.Item onClick={() => handleTypeSelect('DAILY_STATISTIC')}
                       className={selectedType === 'DAILY_STATISTIC' ? 'active' : ''}>
          {t('statistic.daily')}
        </Dropdown.Item>
        {translatedTypes.map((item) => (
          <Dropdown.Item
            key={item.key}
            onClick={() => handleTypeSelect(item.key)}
            className={item.key === selectedType ? 'active' : ''}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropField>
  );
};

export default TypesFilter;
