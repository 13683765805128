import { useTranslation } from 'react-i18next';

export default function HideFiltersButton({showFilters, setShowFilters}) {
  const {t} = useTranslation()
  return (
    <div className="col-2 d-flex align-items-center justify-content-end">
      <button className={`btn fw-bold col-12 filters-button btn-outline-primary ${showFilters ? 'active': ''}`} onClick={()=>setShowFilters(!showFilters)}>
        <span>
          <i className="bi bi-funnel me-1"></i> {t('filters.filters')}
        </span>

      </button>
    </div>
  );
};