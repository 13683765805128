import { Row, Container } from 'react-bootstrap';
import Title from '../../components/blocks/title/meTicketTitle';
import StatisticsContainer from './StatisticsContainer';

const MeTicketPage = () => {

  return (
    <div className='bg-white'>
      <Container className='me-ticket '>
      <Row className='g-4 pb-5'>
        <Title />
        <StatisticsContainer/>
      </Row>
      </Container>
    </div>
  );
};

export default MeTicketPage;
