import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import useRequestLocation from '../../../hooks/useRequestLocation';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import ArrowDown from '../totalScans/svg/ArrowDown';
import ArrowUp from '../totalScans/svg/ArrowUp';
import styled from 'styled-components';
import EmptyTable from './EmptyTable';
import { useTranslation } from 'react-i18next';

const emptyData = Array.from({ length: 8 }).map((_, index) => ({
  id: index + 1,
  country: '',
  city: '',
  scans: '',
  changeScans: '',
  users: '',
  changeUsers: ''
}));

const CountryTableSelected = () => {
  const {
    filters,
    country,
    cities,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    filters: state.filters,
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const { getTableData } = useRequestLocation();

  const fetchTableSelected = async () => {
    const previousData = await getTableData({
      startData: secondPeriodStart,
      endData: secondPeriodEnd
    });
    const currentData = await getTableData({
      startData: periodStart,
      endData: periodEnd
    });
    let configuredData = [];
    if (country || cities.length) {
      configuredData = currentData.dataWithCity[0].top_city.buckets.map(
        (item, index) => {
          const compareData =
            previousData.dataWithCity[0].top_city.buckets.find(
              (el) => el.key === item.key
            );
          const compareScans = compareData?.doc_count
            ? ((item.doc_count * 100) / compareData.doc_count - 100).toFixed(0)
            : 0;
          const compareUsers = compareData?.unique_doc_count?.doc_count
            ? (
                (item.doc_count * 100) /
                  compareData?.unique_doc_count?.doc_count -
                100
              ).toFixed(0)
            : 0;

          return {
            country,
            city: item.key,
            scans: item.doc_count,
            changeScans: compareScans,
            users: item.unique_doc_count.doc_count,
            changeUsers: compareUsers,
            idSelected: index
          };
        }
      );
      if (cities.length) {
        configuredData.sort((a, b) => {
          const aIndex = cities.indexOf(a.city);
          const bIndex = cities.indexOf(b.city);
          if (aIndex === -1) {
            return 1; // move items not in sampleArray to the end of the array
          } else if (bIndex === -1) {
            return -1; // move items not in sampleArray to the end of the array
          } else {
            return aIndex - bIndex; // sort based on index in sampleArray
          }
        });
      }
    } else {
      configuredData = currentData.dataWithCity.map((item, index) => {
        const compareData = previousData.dataWithCity.find(
          (el) =>
            el.key === item.key &&
            el.top_city.buckets[0].key === item.top_city.buckets[0].key
        );
        const compareScans = compareData?.doc_count
          ? ((item.doc_count * 100) / compareData.doc_count - 100).toFixed(0)
          : 0;
        const compareUsers = compareData?.unique_doc_count?.doc_count
          ? (
              (item.doc_count * 100) /
                compareData?.unique_doc_count?.doc_count -
              100
            ).toFixed(0)
          : 0;

        return {
          country: item.key,
          city: item.top_city.buckets[0].key,
          scans: item.doc_count,
          changeScans: compareScans,
          users: item.unique_doc_count.doc_count,
          changeUsers: compareUsers,
          idSelected: index
        };
      });
    }
    setTableData(configuredData);
  };

  useEffect(() => {
    fetchTableSelected();
    // eslint-disable-next-line
  }, [country, cities, filters, page]);

  const formatter = (cell) => (
    <div
      className={classnames('', {
        'text-danger': cell < 0,
        'text-success': cell > 0
      })}
    >
      {cell !== 0 && (
        <>
          {cell < 0 ? <ArrowDown /> : <ArrowUp />} {Math.abs(cell)}%
        </>
      )}
    </div>
  );

  const columns = [
    {
      dataField: 'country',
      text: t('location.country'),
      sort: true
    },
    {
      dataField: 'city',
      text: t('location.city'),
      sort: true
    },
    {
      dataField: 'scans',
      text: t('location.scans'),
      sort: true
    },
    {
      dataField: 'changeScans',
      text: t('location.change'),
      sort: false,
      formatter,
      headerClasses: 'fw-normal'
    },
    {
      dataField: 'users',
      text: t('location.users'),
      sort: true
    },
    {
      dataField: 'changeUsers',
      text: t('location.change'),
      sort: false,
      formatter,
      headerClasses: 'fw-normal'
    }
  ];

  const paginationOptions = {
    sizePerPage: 10,
    paginationSize: 1,
    withFirstAndLast: false,
    alwaysShowAllBtns: false,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  return (
    <TableWrapper>
      <BootstrapTable
        bootstrap4
        keyField="idSelected"
        data={tableData}
        columns={columns}
        bordered={false}
        hover
        wrapperClasses="table-responsive"
        pagination={paginationFactory(paginationOptions)}
        noDataIndication={() => <EmptyTable emptyData={emptyData} />}
      />
    </TableWrapper>
  );
};

export default CountryTableSelected;

export const TableWrapper = styled.div`
  .table-container {
    min-width: 500px;
  }

  .react-bootstrap-table table {
    min-width: 500px;
  }
`;
