import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function TicketsNoData() {
  const [isData, setIsData] = useState(false)
  const filters = useSelector(state => state.filters)
  const {getEventsFilters} = useRequestLocation()

  const {t} = useTranslation()

  useEffect(() => {
    getEventsFilters(filters.periodStart, filters.periodEnd, filters.eventsArray)
      .then(data => setIsData(!!Object.keys(data.salesAndViewsDiagrams).length))
  }, [filters])
  return (
    isData ?
      <></>
      :
      <Col lg={12} xs={12}>
        <div className='px-3 py-2 col-12 rounded-1 fs-7' style={{ backgroundColor: "#FFFAED", color: '#E2B02F' }}>
          <i className="bi bi-info-circle pe-1"></i>
          <span>{t('ticketStatistic.noStatistics')}</span>
        </div>

      </Col>
  );
};