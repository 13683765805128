const ArrowDown = (props) => (
  <svg
    width={8}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 .5c.31 0 .563.252.563.563V7.58l2.414-2.415a.563.563 0 0 1 .796.795L4.398 9.335a.563.563 0 0 1-.796 0L.227 5.96a.563.563 0 0 1 .796-.795L3.437 7.58V1.062C3.438.752 3.69.5 4 .5Z"
      fill="#F44336"
    />
  </svg>
);

export default ArrowDown;
