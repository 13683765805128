import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledPie = styled(Pie)`
  height: 250px;
  width: 250px
`;

const CircleChart = ({ data = [],  index= 0, t}) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {},
    aspectRatio:1,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'medium',
        labels: {
          fontColor: '#3E4957',
          fontSize: 16,
          boxWidth: 13,
          boxHeight: 13,
          font: {
            weight: '400',
            size: 16
          }
        },
      }
    }
  };
  const chartData = {
    labels: [`${t('ticketStatistic.ticketsSold')} (${data[0]})`, `${t('ticketStatistic.ticketsLeft')} (${data[1]})`],
    datasets: [
      {
        labels: [`${t('ticketStatistic.ticketsSold')} (${data[0]})`, `${t('ticketStatistic.ticketsLeft')} (${data[1]})`],
        data: data,
        backgroundColor: [
          colors[(index) % colors.length].bright,
          colors[(index) % colors.length].faded
        ],
        borderColor: '#FFFFFF',
        borderWidth: 1
      }
    ]
  };
  return (
    <StyledPie data={chartData} options={options}/>
  );
};

export default CircleChart;
//
// const PieWrapper = styled.div`
//   canvas {
//     height: 200px !important;
//   }
//   @media (min-width: 992px) {
//     canvas {
//       height: 300px !important;
//     }
//   }
// `;
