const ArrowUp = (props) => (
  <svg width={8} height={10} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.5c.31 0 .563-.252.563-.563V2.421l2.414 2.414a.563.563 0 0 0 .796-.795L4.398.665a.563.563 0 0 0-.796 0L.227 4.04a.563.563 0 0 0 .796.795l2.414-2.414v6.516c0 .311.252.563.563.563Z"
      fill="#4CAF50"
      {...props}
    />
  </svg>
);

export default ArrowUp;
