import moment from 'moment';

export const getPeriodString = (startDate, endDate) =>
  `${moment(startDate).format('MMM DD, YY')} - ${moment(endDate).format(
    'MMM DD, YY'
  )}`;

export const calculateDiff = (startCount, endCount) => {
  if (endCount === 0) {
    return startCount * 100;
  } else if (startCount === 0) {
    return endCount * -100;
  }
  return (((startCount - endCount) * 100) / endCount).toFixed(2);
};
