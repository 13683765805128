import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import ChartCountry from '../../charts/ChartCountry';
import EmptyChartCountry from '../../charts/EmptyChartCountry';

const CountriesItem = ({ modeCompare = false }) => {
  const {
    country,
    cities,
    filters,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    filters: state.filters,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const [labels, setLabels] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const { getCoutryChartData } = useRequestLocation();

  const fetchData = async ({ startData, endData }) => {
    const data = await getCoutryChartData({ startData, endData });
    setLabels(data.map((item) => `${item.key} (${item.doc_count})`));
    setCountryData(data.map((item) => item.doc_count));
  };

  useEffect(() => {
    if (!modeCompare) {
      fetchData({ startData: periodStart, endData: periodEnd });
    } else {
      fetchData({ startData: secondPeriodStart, endData: secondPeriodEnd });
    }
    // eslint-disable-next-line
  }, [country, cities, filters, modeCompare, page]);

  if (!countryData.length) {
    return <EmptyChartCountry />;
  }

  return <ChartCountry labels={labels} countryData={countryData} />;
};

export default CountriesItem;
