import { useTranslation } from 'react-i18next';
import Stat from './stat';
import { Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';

function AvgPriceStat() {
  const {t} = useTranslation()

  const [value, setValue] = useState(undefined)
  const [currency, setCurrency] = useState(undefined)
  const filters = useSelector(state => state.filters)

  const {getEventsFilters} = useRequestLocation()
  useEffect(() => {
    getEventsFilters(filters.periodStart, filters.periodEnd, filters.eventsArray).then(data => {
      setValue(data.averageTicketPrice)
      setCurrency(data.currency)
    })
  }, [filters]);
  return (
    <Col className={"col-md-6"}>
      <Stat icon={'coin'}
            mainLabel={t('ticketStatistic.avgPrice')}
            smallLabel={t('ticketStatistic.avgPriceDescription')}
            value={value}
            currency={currency}
      />
    </Col>
  );
}

export default AvgPriceStat