import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({
  labels,
  dataCounts,
  color,
  dataCounts2 = [],
  color2,
  preLable = '',
  additionalOptions = {},
  colorBorder = {},
  colorBorder2 = {},
  additionalClass = ""
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return `${preLable ? preLable + ': ' : ''}` + data.raw;
          }
        }
      }
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataCounts,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        lineTension: 0.1,
        // borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: dataCounts.length === 1 ? 5 : 0,
        ...additionalOptions,
        ...colorBorder
      },
      {
        data: dataCounts2,
        borderColor: color2,
        backgroundColor: color2,
        fill: false,
        lineTension: 0.1,
        // borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBackgroundColor: color2,
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color2,
        pointHoverBorderColor: color2,
        pointHoverBorderWidth: 2,
        pointRadius: dataCounts2.length === 1 ? 5 : 0,
        ...additionalOptions,
        ...colorBorder2
      }
    ]
  };

  return <Line options={options} data={data} className={`my-4 ${additionalClass}`} />;
};

export default LineChart;
