import React from 'react';
import styles from './loader.module.scss';

const Loader = () => (
  <div className={styles.loaderContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
      <path
        className={styles.loaderPath}
        stroke="#9B27AF"
        strokeWidth={4}
        d="M9 16a7 7 0 1 1 7-7"
      />
    </svg>
  </div>
);

export default Loader;
