import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartCountry = ({ labels, countryData }) => {
  const { isCompareMode } = useSelector((state) => ({
    isCompareMode: state.filters.isCompareMode
  }));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: isCompareMode && screenWidth > 991 ? 'right' : 'bottom',
        labels: {
          fontColor: '#3E4957',
          fontSize: 16,
          boxWidth: 16,
          boxHeight: 16
        }
      },
      title: {
        display: false
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: countryData,
        backgroundColor: [
          '#A803BF',
          '#700E7F',
          '#530F5F',
          '#D455E5',
          '#E1D5FF',
          '#8B929A'
        ],
        borderColor: '#FFFFFF',
        borderWidth: 1
      }
    ]
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PieWrapper isCompareMode={isCompareMode}>
      <Pie data={data} options={options} className="h-100" />
    </PieWrapper>
  );
};

export default ChartCountry;

const PieWrapper = styled.div`
  height: 300px;

  @media (min-width: 992px) {
    height: ${(props) => (props.isCompareMode ? 350 : 450)}px;
  }
`;
