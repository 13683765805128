import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggle: false,
  dataLoad: {
    isGetCoutryCityCountLoad: false,
    isGetCoutryChartDataLoad: false,
    isGetTableDataLoad: false,
    isGetMapDataLoad: false,
    isGetTotalScansLoad: false,
    isGetScansDayLoad: false,
    isGetCountriesLoad: false,
    isGetDevicesLoad: false,
    isGetFilteredTotalScansLoad: false,
    isGetFilteredTotalUsersLoad: false,
    isGetOsLoad: false,
    isGetAllCountriesLoad: false,
    isGetCitiesByCountryLoad: false
  }
};

const preloader = createSlice({
  name: 'preloader',
  initialState,
  reducers: {
    setPreloader(state, { payload }) {
      state.toggle = payload;
    },
    setDataLoad(state, { payload }) {
      state.dataLoad = { ...state.dataLoad, ...payload };
    }
  }
});
export const { setPreloader, setDataLoad } = preloader.actions;
export default preloader.reducer;
