import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_ARRAY } from '../../../constants/languages';
import useUrlParams from '../../../hooks/useUrlParams';

const RegisterBlock = () => {
  const { t } = useTranslation();
  const { baseUrl } = useUrlParams();
  const base = window.location.pathname.split('/')[1];
  const registerPath = `${baseUrl}${
    LANGUAGES_ARRAY.includes(base) && base !== 'en' ? `/${base}` : ''
  }/register`;

  return (
    <section>
      <Row>
        <Col>
          <div className="p-4 bg-danger bg-opacity-10 border-start border-5 border-danger rounded position-relative">
            <div className="d-lg-flex justify-content-between align-items-center">
              <div>
                <p className="fw-bold mb-0">{t('register.title')}!</p>
                <p className="mb-0">{t('register.description')}</p>
              </div>
              <div className="col-lg-2 pe-lg-4 mt-lg-0 mt-3">
                <small>
                  <a
                    href={registerPath}
                    className="btn btn-success fw-bold text-white w-100 text-capitalize"
                    target="_parent"
                  >
                    {t('register.signup')}
                  </a>
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default RegisterBlock;
