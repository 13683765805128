import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartDayTime = ({ labels, dataTotal, dataUniq }) => {
  const { t } = useTranslation();
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return (
              `${
                data.datasetIndex === 0
                  ? t('scans.total') + ': '
                  : t('scans.unique') + ': '
              }` + data.raw
            );
          }
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        data: dataTotal,
        backgroundColor: '#9B27AF'
      },
      {
        data: dataUniq,
        backgroundColor: '#796FEF'
      }
    ]
  };
  return (
    <div style={{ height: 250 }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ChartDayTime;
