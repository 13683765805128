import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ColorLabel } from '../../elements/general';
import { MONTH, YEAR, DAY, WEEK } from '../../../constants';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import { getFormatedLabel } from '../totalUsersChart';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RecordBarChart from '../../charts/RecordBarChart';

const UniqueUsersChart = () => {

  const {
    isFitersActive,
    periodStart,
    periodEnd,
    filters,
    master,
    haircutType,
    service
  } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    filters: state.filters,
    master: state.filters.master,
    haircutType: state.filters.haircutType,
    service: state.filters.service
  }));
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(DAY);
  const [labels, setLabels] = useState([]);
  const [dataCounts, setDataCounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataCounts2, setDataCounts2] = useState([]);
  const [total2, setTotal2] = useState(0);
  const [dataCounts3, setDataCounts3] = useState([]);

  const { getRecords } = useRequestLocation();

  // const updateChartData = (countDates) => {
  //   setLabels(
  //     countDates.map((item) =>
  //       getFormatedLabel(item.key_as_string, activeButton)
  //     )
  //   );
  //   setDataCounts(countDates.map((item) => item.doc_count));
  //   setTotal(countDates.reduce((acc, item) => acc + item.doc_count, 0));
  // };


  useEffect(() => {
    const periodToStart = periodStart !== null
      ? moment(periodStart).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = periodEnd !== null
      ? moment(periodEnd).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getRecords(periodToEnd, periodToStart, master, haircutType, service).then((countDates) => {
      setDataCounts(countDates.map((el) => {
        const statusItem = el.group_by_status.buckets.find(record => record.key === 6)
        const statusItem2 = el.group_by_status.buckets.find(record => record.key === 7)
        return (statusItem ? statusItem.doc_count : 0)
          + (statusItem2 ? statusItem2.doc_count: 0);
      }));

      setDataCounts2(countDates.map((el) => {
        const statusItem = el.group_by_status.buckets.find(record => record.key === 4)
        return statusItem ?
          statusItem.doc_count :
          0;
      }));

      setDataCounts3(countDates.map((el) => {
        const statusItem = el.group_by_status.buckets.find(record => record.key === 5)
        return statusItem ?
          statusItem.doc_count :
          0;
      }));

      setLabels(
        countDates.map((item) =>
          getFormatedLabel(item.key_as_string, activeButton)
        )
      );
    });
  }, [filters]);

  return (
    <Col xs={12} lg={12}>
      <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
        <div className="d-flex flex-column justify-content-between">
          <Col xs={12} lg="auto">
            <h4 className="h4-heads mb-2 text-capitalize">
              {t('statistic.records')}
            </h4>
          </Col>
          <div className='d-lg-flex align-items-center justify-content-center gap-4 align-self-lg-center'>
            <Col
              lg={4}
              className="text-gray d-flex justify-content-lg-center align-items-center"
            >
              <ColorLabel color='#E8F7F4' borderColor="#1AB394" borderRadius='2px'/>
              <div className='primary-color font-16'>{t('statistic.recordComplete')}</div>
            </Col>
            <Col
              lg={4}
              xs="auto"
              className="text-gray d-flex justify-content-lg-center align-items-center"
            >
              <ColorLabel borderColor='#5842BE' color='#EEECF9' borderRadius='2px'/>
              <div className='primary-color font-16'>{t('statistic.clientNotCome')}</div>
            </Col>
            <Col
              lg={4}
              xs="auto"
              className="text-gray d-flex justify-content-lg-center align-items-center"
            >
              <ColorLabel color='#FFF3F4' borderColor='#DC3545' borderRadius='2px'/>
              <div className='primary-color font-16'>{t('statistic.recordCancel')}</div>
            </Col>
          </div>
        </div>
        <Row className="justify-content-center">
          <RecordBarChart
            labels={labels}
            dataCounts={dataCounts}
            color="#796FEF"
            dataCounts2={dataCounts2}
            color2="#B3AEF0"
            dataCounts3={dataCounts3}
            preLable={"К-сть записів"}
          />
        </Row>
      </div>
    </Col>
  );
};

export default UniqueUsersChart;
