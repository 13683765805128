import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import LocationFilter from './../LocationFilter';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setFilters } from '../../../../redux/slices/filters';
import DeviceFilter from './../DeviceFilter';
import moment from 'moment';
import { useEffect } from 'react';
import MePosDateFilter from './mePosDateFilter';
import TypesFilter from './TypesFilter';
import MasterSelectFilter from './MasterSelectFilter';
import HaircutSelectFilter from './HaircutSelectFilter';
import ServiceSelectFilter from './ServiceSelectFilter';
import { useTranslation } from 'react-i18next';

// const EXCLUDED_FIELDS = [
//   'location',
//   'keywords',
//   'gclid',
//   'audience',
//   'unique',
//   'premium',
//   'banner-id',
//   'is-react-qr-generator',
//   'entry-connected-titles',
//   'me-city-connected',
//   'user-email',
//   'user-earned',
//   'unique-one-day',
//   'banner-type',
//   'entry_has_10_scans',
//   'ads_earned',
//   'exact-location'
// ];


const START_DATE_RANGE = {
  selection1: {
    startDate: moment().subtract(1, 'week').toDate(),
    endDate: moment().toDate(),
    key: 'selection1'
  }
};

const TYPE_OF_STATISTIC = 'DAILY_STATISTIC'

const Filters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(START_DATE_RANGE);
  const [statisticType, setStatisticType] = useState(TYPE_OF_STATISTIC)
  const [master, setMaster] = useState('all')
  const [haircutType, setHaircutType] = useState('all')
  const [service, setService] = useState('all')

  const handleApply = () => {
    if (
      !statisticType &&
      master === 'all' &&
      haircutType === 'all' &&
      service === 'all' &&
      JSON.stringify(dateRange) === JSON.stringify(START_DATE_RANGE)
    ) {
      return;
    }
    const filterOptions = {
      statisticType: statisticType,
      master: master,
      haircutType: haircutType,
      service: service,
      periodStart: dateRange.selection1.startDate
        ? moment(dateRange.selection1.startDate).startOf('D').toDate()
        : null,
      periodEnd: dateRange.selection1.endDate
        ? moment(dateRange.selection1.endDate).endOf('D').toDate()
        : null,
    };
    dispatch(setFilters(filterOptions));
  };

  const handleReset = () => {
    setMaster('all')
    setHaircutType('all')
    setService('all')
    setDateRange(START_DATE_RANGE)
    setStatisticType(TYPE_OF_STATISTIC)
    dispatch(resetFilters())
  }


  return (
    <div className='d-flex flex-column justify-content-between'>
      <div className="pb-3 pb-lg-4">
        <div className='d-flex flex-column gap-lg-4 gap-2'>
          <Row className="gy-2" lg={12} xs={12}>
            <Col xs={12} lg={6}>
              <MePosDateFilter dateRange={dateRange} setDateRange={setDateRange} />
            </Col>
            <Col xs={12} lg={6}>
              <TypesFilter selectedType={statisticType} setSelectedType={setStatisticType}/>
            </Col>
          </Row>
          <Row className="gy-2">
            <Col xs={12} lg={4}>
              <MasterSelectFilter startData={dateRange.selection1.startDate}
                                  endData={dateRange.selection1.endDate}
                                  selectedMaster={master}
                                  setSelectedMaster={setMaster}
                                  haircutType={haircutType}
                                  service={service}
              />
            </Col>
            <Col xs={12} lg={4}>
              <HaircutSelectFilter startData={dateRange.selection1.startDate}
                                   endData={dateRange.selection1.endDate}
                                   selectedMaster={master}
                                   setHairstyles={setHaircutType}
                                   selectedHairstyle={haircutType}
                                   setService={setService}
              />
            </Col>
            <Col xs={12} lg={4}>
              <ServiceSelectFilter startData={dateRange.selection1.startDate}
                                   endData={dateRange.selection1.endDate}
                                   master={master}
                                   haircutType={haircutType}
                                   service={service}
                                   setService={setService}
              />
            </Col>
          </Row>
          <Col className="justify-content-lg-end mt-3 mt-lg-0">
            <Col xs={12}  lg={5} className='ms-lg-auto'>
              <Row>
            <Col xs={6} lg={6}>
              <button className="btn w-100 h-100 apply-btn rounder-4px" onClick={handleApply}>
                {t('filters.apply')}
              </button>
            </Col>
            <Col xs={6} lg={6}>
              <button className="btn w-100 h-100 clear-btn rounder-4px" onClick={handleReset}>
                {t('filters.cancel')}
              </button>
            </Col>
              </Row>
            </Col>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Filters;

