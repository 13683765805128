import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RecordBarChart = ({
                     labels,
                     dataCounts,
                     color,
                     dataCounts2 = [],
                     color2,
                     dataCounts3 = [],
                     color3,
                     preLable = ''
                   }) => {
  const {t} = useTranslation()
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return (
              `${
                data.datasetIndex === 0
                  ? `${t('statistic.recordComplete')}: `
                  : data.datasetIndex === 1
                    ? `${t('statistic.clientNotCome')}: `:
                    `${t('statistic.recordCancel')}: `
              }` + data.raw
            );
          }
        }
      }
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataCounts,
        borderColor: '#1AB394',
        borderWidth: 1.5,
        marginLeft: 1,
        backgroundColor: '#E8F7F4',
        borderRadius: 9,
        strokeColor: '#1AB394',
        fill: true,
        lineTension: 0.1,
        // borderCapStyle: 'butt',
        // borderJoinStyle: 'miter',
        pointBackgroundColor: '#1AB394',
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#1AB394',
        pointHoverBorderColor: '#1AB394',
        pointHoverBorderWidth: 3,
        pointRadius: dataCounts.length === 1 ? 5 : 0
      },
      {
        data: dataCounts2,
        borderColor: 'blue',
        borderWidth: 1.5,
        backgroundColor: '#EEECF9',
        borderRadius: 9,
        strokeColor: '#5842BE',
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#5842BE',
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#5842BE',
        pointHoverBorderColor: '#5842BE',
        pointHoverBorderWidth: 3,
        pointRadius: dataCounts2.length === 1 ? 5 : 0
      },
      {
        data: dataCounts3,
        borderColor: 'red',
        borderWidth: 1.5,
        backgroundColor: '#FFF3F4',
        borderRadius: 9,
        strokeColor: '#DC3545',
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#DC3545',
        pointBorderWidth: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#DC3545',
        pointHoverBorderColor: '#DC3545',
        pointHoverBorderWidth: 3,
        pointRadius: dataCounts3.length === 1 ? 5 : 0
      },
    ]
  };

  return <Bar options={options} data={data} className="my-4" />;
};

export default RecordBarChart;
