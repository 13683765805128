import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { DropField } from '../../elements/general';
import { Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCity } from '../../../redux/slices/locationScans';
import { StyledDropDown } from './CountrySelect';
import { useTranslation } from 'react-i18next';

const CitySelect = () => {
  const { country, citiesNames } = useSelector((state) => ({
    country: state.locationScans.country,
    citiesNames: state.locationScans.cities
  }));
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const { getCitiesByCountry } = useRequestLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (country.length) {
      getCitiesByCountry(country).then((data) =>
        setCities(data.map((item) => item.key))
      );
    } else {
      setCities([]);
    }
    // eslint-disable-next-line
  }, [country]);

  const handleSelect = (cityName) => {
    dispatch(setCity(cityName));
  };

  if (!country) {
    return null;
  }

  return (
    <Col xs={6} lg="auto">
      <StyledDropDown>
        <DropField className="me-1">
          <Dropdown.Toggle className="form-select pe-3 me-3">
            {t('location.city')}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {cities.map((item) => (
              <Dropdown.Item
                key={item}
                onClick={() => handleSelect(item)}
                disabled={citiesNames.some((elem) => elem === item)}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </DropField>
      </StyledDropDown>
    </Col>
  );
};

export default CitySelect;
