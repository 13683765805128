import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { DropField } from '../../elements/general';
import { Col, Dropdown, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCountry } from '../../../redux/slices/locationScans';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CountrySelect = () => {
  const { country, location, periodStart, periodEnd, filters, page, loading } =
    useSelector((state) => ({
      country: state.locationScans.country,
      location: state.filters.location,
      periodStart: state.filters.periodStart,
      periodEnd: state.filters.periodEnd,
      filters: state.filters,
      page: state.meQrData.pagination.page,
      loading: state.preloader.dataLoad.isGetAllCountriesLoad
    }));
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const { getAllCountries } = useRequestLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isActiveDropdown) {
      return;
    }
    getAllCountries({
      startData: periodStart,
      endData: periodEnd
    }).then((data) => setCountries(data.map((item) => item.key)));
    // eslint-disable-next-line
  }, [filters, page, isActiveDropdown]);

  useEffect(() => {
    dispatch(setCountry(location));
  }, [location, dispatch]);

  const handleSelect = (countryName) => {
    dispatch(setCountry(countryName));
  };

  return (
    <Col xs={6} lg="auto">
      <StyledDropDown>
        <DropField className="me-1" onClick={() => setIsActiveDropdown(true)}>
          <Dropdown.Toggle className="form-select pe-3 me-3">
            {t('location.country')}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {loading ? (
              <div className="d-flex justify-content-center my-2">
                <Spinner animation="border" role="status" className="m-auto" />
              </div>
            ) : (
              countries.map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => handleSelect(item)}
                  disabled={item === country}
                >
                  {item}
                </Dropdown.Item>
              ))
            )}
          </Dropdown.Menu>
        </DropField>
      </StyledDropDown>
    </Col>
  );
};

export default CountrySelect;

export const StyledDropDown = styled.div`
  button {
    padding-left: 0;
    padding-right: 0;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
`;
