import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import RecordsChartHorizontalBar from '../../charts/recordsChartHorBar';
import { useTranslation } from 'react-i18next';

const ServiceFinishingStat = () => {
  const {
    isFitersActive,
    periodStart,
    periodEnd,
    filters,
    master,
    haircutType,
    service
  } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    filters: state.filters,
    master: state.filters.master,
    haircutType: state.filters.haircutType,
    service: state.filters.service
  }));

  const [labels, setLabels] = useState([]);
  const [dataCounts, setDataCounts] = useState([]);
  const [dataCounts2, setDataCounts2] = useState([]);
  const [dataCounts3, setDataCounts3] = useState([]);

  const [widthSize, setWidthSize] = useState('full')
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      if (innerWidth >= 1440) {
        setWidthSize('full');
      } else if (innerWidth >= 1200) {
        setWidthSize('medium');
      } else if (innerWidth >= 1024) {
        setWidthSize('small');
      } else {
        setWidthSize('tiny');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [innerWidth]);

  function truncateTextWithEllipsis(text) {
    let maxCharacters = 50
    if (widthSize === 'full') {
      maxCharacters = 50
    } else if (widthSize === 'medium') {
      maxCharacters = 42
    } else if (widthSize === 'small') {
      maxCharacters = 35
    } else if (widthSize === 'tiny') {
      maxCharacters = 27
    }
    if (text.length <= maxCharacters) {
      return text
    }
    const truncatedText = text.slice(0, maxCharacters); // Обрезаем строку до нужного количества символов (минус 3 для троеточия).
    return truncatedText + '...';
  }

  const { getServicePop } = useRequestLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const periodToStart = periodStart !== null
      ? moment(periodStart).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = periodEnd !== null
      ? moment(periodEnd).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getServicePop(periodToEnd, periodToStart, master, haircutType, service, false).then((services) => {
      setDataCounts(services.status_service.buckets.map((el) => {
        const statusItem = el.status.buckets.find(record => record.key === 6)
        const statusItem2 = el.status.buckets.find(record => record.key === 7)
        return (statusItem ? statusItem.doc_count : 0)
          +
          (statusItem2 ? statusItem2.doc_count: 0);
      }));
      setDataCounts2(services.status_service.buckets.map((el) => {
        const statusItem = el.status.buckets.find(record => record.key === 4)
        return statusItem ?
          statusItem.doc_count :
          0;
      }));
      setDataCounts3(services.status_service.buckets.map((el) => {
        const statusItem = el.status.buckets.find(record => record.key === 5)
        return statusItem ?
          statusItem.doc_count :
          0;
      }));
      setLabels(
        services.status_service.buckets.map((item) => truncateTextWithEllipsis(item.key))
      );
    });
  }, [filters]);


  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
          <Col xs={12} lg="auto">
            <div className="h4-heads mb-2 text-capitalize">
              {t('statistic.recordPopularity')}
            </div>
          </Col>
          <Row className="justify-content-center">
            <RecordsChartHorizontalBar
              label={`${t('statistic.recordComplete')}: `}
              labels={labels}
              dataCounts={dataCounts}
              color="#EEECF9"
              borderColor="#5842BE"
              addtitionalData = {{
                borderColor: '#1AB394',
                borderWidth: 1.5,
                marginLeft: 1,
                backgroundColor: '#E8F7F4',
                borderRadius: 9,
                strokeColor: '#1AB394',
                fill: true,
                lineTension: 0.1,
                pointBackgroundColor: '#1AB394',
                pointBorderWidth: 3,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1AB394',
                pointHoverBorderColor: '#1AB394',
                pointHoverBorderWidth: 3,
                pointRadius: dataCounts.length === 1 ? 5 : 0
              }}
              additionalDatasets={[
                {
                  data: dataCounts3,
                  borderColor: 'red',
                  borderWidth: 1.5,
                  backgroundColor: '#FFF3F4',
                  borderRadius: 9,
                  strokeColor: '#DC3545',
                  fill: true,
                  lineTension: 0.1,
                  pointBackgroundColor: '#DC3545',
                  pointBorderWidth: 3,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#DC3545',
                  pointHoverBorderColor: '#DC3545',
                  pointHoverBorderWidth: 3,
                  pointRadius: dataCounts3.length === 1 ? 5 : 0
                },
                {
                  data: dataCounts2,
                  borderColor: 'blue',
                  borderWidth: 1.5,
                  backgroundColor: '#EEECF9',
                  borderRadius: 9,
                  strokeColor: '#5842BE',
                  fill: true,
                  lineTension: 0.1,
                  pointBackgroundColor: '#5842BE',
                  pointBorderWidth: 3,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#5842BE',
                  pointHoverBorderColor: '#5842BE',
                  pointHoverBorderWidth: 3,
                  pointRadius: dataCounts2.length === 1 ? 5 : 0
                },
              ]}
            />
          </Row>
        </div>
      </Col>
    </Row>

  );
};

export default ServiceFinishingStat;
