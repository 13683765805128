import { useTranslation } from 'react-i18next';
import Stat from './stat';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

function SellsStat() {
  const {t} = useTranslation()

  const [value, setValue] = useState(undefined)
  const [currency, setCurrency] = useState(undefined)
  const [dataByPeriod, setDataByPeriod] = useState(undefined)
  const [percent, setPercent] = useState(null)


  const filters = useSelector(state => state.filters)

  const {getEventsFilters} = useRequestLocation()
  useEffect(() => {
    getEventsFilters(filters.periodStart, filters.periodEnd, filters.eventsArray).then(data => {
      if (data.summaryViews !== data.summaryViewsByPeriod && filters.periodStart && filters.periodEnd) {
        const momentStartDate = moment.isMoment(filters.periodStart) ? filters.periodStart : moment(filters.periodStart);
        const momentEndDate = moment.isMoment(filters.periodEnd) ? filters.periodEnd : moment(filters.periodEnd);
        const differ = momentEndDate.diff(momentStartDate, 'days') + 1
        getEventsFilters(
          momentStartDate.clone().subtract(differ, 'days'),
          momentEndDate.clone().subtract(differ, 'days'),
          filters.eventsArray)
          .then(previousData => {
            const previousValue = previousData.summarySalesByPeriod
            const percentage = (data.summarySalesByPeriod-previousValue)/previousValue * 100
            setPercent(parseInt(percentage))
          })
      }
      setValue(data.summarySales)
      setCurrency(data.currency)
      setDataByPeriod(data.summarySalesByPeriod)
    })
  }, [filters]);
  return (
      <Stat icon={'cash-coin'}
            mainLabel={t('ticketStatistic.salesSummary')}
            smallLabel={t('ticketStatistic.salesSummaryDescription')}
            value={value}
            percentage={percent}
            currency={currency}
            dataByPeriod={dataByPeriod}
      />
  );
}

export default SellsStat