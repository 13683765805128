const TotalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={75}
    fill="none"
    {...props}
  >
    <path
      fill="#F5F5F5"
      d="M0 2.375A2.312 2.312 0 0 1 2.313.062h13.874a2.312 2.312 0 1 1 0 4.625H4.626V16.25a2.312 2.312 0 1 1-4.625 0V2.375ZM55.5 2.375A2.312 2.312 0 0 1 57.813.062h13.874A2.312 2.312 0 0 1 74 2.376V16.25a2.312 2.312 0 1 1-4.625 0V4.687H57.812A2.312 2.312 0 0 1 55.5 2.376ZM2.313 55.563a2.312 2.312 0 0 1 2.312 2.312v11.563h11.563a2.312 2.312 0 1 1 0 4.624H2.313A2.312 2.312 0 0 1 0 71.75V57.875a2.312 2.312 0 0 1 2.313-2.313ZM71.688 55.563A2.312 2.312 0 0 1 74 57.874V71.75a2.312 2.312 0 0 1-2.313 2.313H57.813a2.312 2.312 0 1 1 0-4.626h11.562V57.876a2.312 2.312 0 0 1 2.313-2.313ZM18.5 18.563h4.625v4.625H18.5v-4.625Z"
    />
    <path
      fill="#F5F5F5"
      d="M32.375 9.313H9.25v23.124h23.125V9.314Zm-18.5 4.624H27.75v13.876H13.875V13.938ZM23.125 50.938H18.5v4.624h4.625v-4.624Z"
    />
    <path
      fill="#F5F5F5"
      d="M32.375 41.688H9.25v23.124h23.125V41.689Zm-18.5 4.624H27.75v13.876H13.875V46.312ZM50.875 18.563H55.5v4.625h-4.625v-4.625Z"
    />
    <path
      fill="#F5F5F5"
      d="M41.625 9.313H64.75v23.124H41.625V9.314Zm4.625 4.624v13.876h13.875V13.938H46.25ZM37 37.063v9.25h4.625v4.624H37v4.626h9.25v-9.25h4.625v9.25H55.5v-4.626h9.25v-4.624H50.875v-9.25H37Zm9.25 9.25h-4.625v-4.626h4.625v4.626ZM64.75 55.563h-4.625v4.624h-9.25v4.626H64.75v-9.25ZM46.25 64.813v-4.626H37v4.626h9.25Z"
    />
    <path fill="#F5F5F5" d="M55.5 41.688h9.25v-4.626H55.5v4.626Z" />
  </svg>
);

export default TotalIcon;
