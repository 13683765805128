import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useEffect } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../elements/general';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const LocationFilter = ({ selectedCountry, setSelectedCountry }) => {
  const { filters, page, loading } = useSelector((state) => ({
    filters: state.filters,
    page: state.meQrData.pagination.page,
    loading: state.preloader.dataLoad.isGetCountriesLoad
  }));
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  const { getCountries } = useRequestLocation();
  useEffect(() => {
    if (!isActiveDropdown) {
      return;
    }
    getCountries().then((data) => {
      setCountries(data.map((item) => item.key));
    });
    // eslint-disable-next-line
  }, [filters, page, isActiveDropdown]);

  useEffect(() => {
    setFilteredCountries(countries);
  }, [countries]);

  const handleCountrySelect = (location) => {
    setSelectedCountry(location);
    setSearchValue('');
    setFilteredCountries(countries);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filtered = countries.filter((item) =>
      item.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  return (
    <DropField onClick={() => setIsActiveDropdown(true)}>
      <FloatingLabel label={t('filters.location')}>
        <Dropdown.Toggle className="form-select">
          <div className="me-3 overflow-hidden text-truncate">
            {selectedCountry || t('filters.allCountries')}
          </div>
        </Dropdown.Toggle>
      </FloatingLabel>

      <Dropdown.Menu>
        <Dropdown.Item className="px-1">
          <Form.Control
            className="border-0"
            type="text"
            placeholder={`${t('filters.search')}...`}
            onChange={handleSearch}
            value={searchValue}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => handleCountrySelect('')}>
          {t('filters.allCountries')}
        </Dropdown.Item>
        {loading ? (
          <div className="d-flex justify-content-center my-2">
            <Spinner animation="border" role="status" className="m-auto" />
          </div>
        ) : (
          filteredCountries.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleCountrySelect(item)}
            >
              {item}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </DropField>
  );
};

export default LocationFilter;
