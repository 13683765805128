import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

ChartJS.register(ArcElement);

const EmptyChartCountry = () => {
  const { isCompareMode } = useSelector((state) => ({
    isCompareMode: state.filters.isCompareMode
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  };

  const data = {
    datasets: [
      {
        data: [3, 3, 10, 18, 26, 40],
        backgroundColor: [
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4'
        ],
        borderColor: '#FFFFFF',
        borderWidth: 1
      }
    ]
  };

  return (
    <PieWrapper isCompareMode={isCompareMode}>
      <Pie data={data} options={options} className="h-100" />
    </PieWrapper>
  );
};

export default EmptyChartCountry;

const PieWrapper = styled.div`
  height: 300px;

  @media (min-width: 992px) {
    height: ${(props) => (props.isCompareMode ? 350 : 450)}px;
  }
`;
