import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DeviceChart from '../../charts/DeviceChart';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmptyDeviceChart from '../../charts/EmptyDeviceChart';

const COUNT_DATA = 5;

const OS = () => {
  const {
    filters,
    isCompareMode,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    filters: state.filters,
    isCompareMode: state.filters.isCompareMode,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const [osData, setOsData] = useState([]);
  const [osData2, setOsData2] = useState([]);

  const { getOs } = useRequestLocation();

  useEffect(() => {
    getOs({ startData: periodStart, endData: periodEnd }).then((data) => {
      setOsData(getOsCount(data));
    });
    if (isCompareMode) {
      getOs({
        startData: secondPeriodStart,
        endData: secondPeriodEnd
      }).then((data) => {
        setOsData2(getOsCount(data));
      });
    }
    // eslint-disable-next-line
  }, [filters, page]);

  const getOsCount = (data) => {
    const OsType = data
      .map((item) => ({
        os: item.key,
        count: item.doc_count
      }))
      .reduce((acc, { os, count }) => {
        acc[os] = (acc[os] || 0) + count;
        return acc;
      }, {});
    const sortedData = Object.entries(OsType).sort((a, b) => b[1] - a[1]);
    if (sortedData.length > COUNT_DATA) {
      const otherData = sortedData
        .slice(COUNT_DATA)
        .reduce((acc, item) => (acc += item[1]), 0);
      return [...sortedData.slice(0, COUNT_DATA), ['Other', otherData]];
    }
    return sortedData;
  };

  if (isCompareMode) {
    return (
      <Col xs={12}>
        <Row className="justify-content-between align-items-center mb-4">
          <Col xs={12}>
            <div className="fw-bold fs-5 text-capitalize">
              {t('devices.os')}
            </div>
          </Col>
          <Col xs={12}>
            <div className="fs-7 text-gray">{t('devices.osDescription')}</div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center gy-3">
          <Col xs={12} lg={6}>
            {osData2.length ? (
              <DeviceChart
                data={osData2}
                color={'os'}
                title={t('devices.previous')}
              />
            ) : (
              <EmptyDeviceChart />
            )}
          </Col>
          <Col xs={12} lg={6}>
            {osData.length ? (
              <DeviceChart
                data={osData}
                color={'os'}
                title={t('devices.selected')}
              />
            ) : (
              <EmptyDeviceChart />
            )}
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col xs={12} lg={6}>
      <Row className="justify-content-between align-items-center mb-3">
        <Col xs={12}>
          <div className="fw-bold fs-5 text-capitalize">{t('devices.os')}</div>
        </Col>
        <Col xs={12}>
          <div className="fs-7 text-gray">{t('devices.osDescription')}</div>
        </Col>
      </Row>
      {osData.length ? (
        <DeviceChart data={osData} color={'os'} />
      ) : (
        <EmptyDeviceChart />
      )}
    </Col>
  );
};

export default OS;
