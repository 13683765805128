import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(ArcElement);

const EmptyDeviceChart = () => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  };

  const data = {
    datasets: [
      {
        data: [3, 3, 10, 18, 26, 40],
        backgroundColor: [
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4',
          '#DDE0E4'
        ],
        borderColor: '#FFFFFF',
        borderWidth: 1
      }
    ]
  };

  return (
    <PieWrapper>
      <Pie data={data} options={options} className="h-100" />
    </PieWrapper>
  );
};

export default EmptyDeviceChart;

const PieWrapper = styled.div`
  canvas {
    height: 200px !important;
  }
  @media (min-width: 992px) {
    canvas {
      height: 300px !important;
    }
  }
`;
