import { useTranslation } from 'react-i18next';
import CircleChart from './CircleChart';

function TypeCircleChart({index, eventData, title= ""}) {
  const {t} = useTranslation()

  return (
    <div className={`col d-flex flex-column align-items-center justify-content-between ${eventData.total_sold_tickets === 0 ? 'd-none' : ''}`}>
      <div>
        <div style={{fontWeight:600, fontSize: '16px'}}>{title}</div>
        <div className="d-flex align-items-center ps-2">
          <i className="bi bi-ticket-perforated pe-1 opacity-50 align" style={{fontSize: "14px"}}></i>
          <div><span className="opacity-50 pe-1">{t('ticketStatistic.numberTickets')}:</span><span>{eventData.total_created_tickets}</span></div>
        </div>
      </div>
      <div>
        <CircleChart index={index} t={t} data={[eventData.total_sold_tickets, eventData.ticket_left]}/>
      </div>
    </div>
  );
}

export default TypeCircleChart