import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';

const Title = () => {
  const { t } = useTranslation()
  return (
    <Col xs={12} lg={8}>
      <h2 className='mt-5'>
        {t('ticketHeader.ticketTitle')}
      </h2>
      {/*<h2 className='fw-bold fs-2 mt-4 d-lg-none'>*/}
      {/*  {t('ticketHeader.ticketTitle')}*/}
      {/*</h2>*/}
      <p className='text-gray mb-lg-3'> {t('ticketHeader.ticketDescription')}</p>
    </Col>
  );
};

export default Title;