import { Col } from 'react-bootstrap';
import formatNumber from '../../../services/MeTicketAdditional/FormatNumber';

function Stat({
                mainLabel,
                smallLabel,
                icon,
                percentage= 0,
                value= 0,
                currency = null,
                dataByPeriod= undefined
}) {
  const number = formatNumber(value)
  return (
    <Col xs={12} lg={12} className={`border col-6 d-flex justify-content-between align-items-center rounded-1 p-4 mb-3`}
        style={{minBlockSize: '200px'}}>
      <div>
        <h4>{mainLabel}</h4>
        <small className="opacity-50">{smallLabel}</small>
        {dataByPeriod === value || dataByPeriod === undefined || !value ?
          <h3 className="mb-0 mt-4">{currency} {number}</h3>
          :
          <div>
            <div className="d-flex align-items-end mt-4">
              <h3 className="m-0 pe-1">{currency} {dataByPeriod}</h3>
              {!Number.isNaN(percentage) && isFinite(percentage) && percentage !== null ?
                <>
                  {percentage !== 0
                  ? <i style={{color: percentage < 0 ? '#F44336': '#4CAF50'}}
                       className={`bi bi-arrow-${percentage < 0 ? 'down': 'up'}-short align-self-center`}></i>
                  : <></>}

                  <span style={{color: !percentage ? '#8B929A' : percentage < 0 ? '#F44336': '#4CAF50'}}
                        className="pe-1">{percentage}%</span>
                </>
              :<></>}

              <span>/ {currency} {value}</span>
            </div>
          </div>
        }
      </div>
      <span className={`bi bi-${icon} m-0 opacity-25 mx-3`} style={{fontSize: '5rem'}}></span>
    </Col>
  );
}

export default Stat