import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { BsXCircle } from 'react-icons/bs';
import LocationFilter from './LocationFilter';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setFilters } from '../../../redux/slices/filters';
import DeviceFilter from './DeviceFilter';
import DateFilter from './DateFilter';
import moment from 'moment';
import { useEffect } from 'react';
import QrFilter from './QrFilter';
import { useTranslation } from 'react-i18next';
import useUrlParams from '../../../hooks/useUrlParams';
import Loader from '../../loader';
import RegisterBlock from './RegisterBlock';
import PaginationMeQr from '../pagination/PaginationMeQr';
import { setFolderId } from '../../../redux/slices/meQrData';
import ExportCsv from './ExportCsv';

const START_DATE_RANGE = {
  selection1: {
    startDate: moment().subtract(1, 'week').toDate(),
    endDate: moment().toDate(),
    key: 'selection1'
  }
};

const Filters = () => {
  const { isFitersActive, maxPage } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    maxPage: state.meQrData.pagination.maxPage
  }));
  const { t } = useTranslation();
  const { noUser } = useUrlParams();
  const dispatch = useDispatch();

  const [selectedQrs, setSelectedQrs] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [dateRange, setDateRange] = useState(START_DATE_RANGE);
  const [showLoader, setShowLoader] = useState(false);

  const handleApply = () => {
    if (
      !selectedQrs.length &&
      !selectedCountry &&
      !selectedCountry &&
      JSON.stringify(dateRange) === JSON.stringify(START_DATE_RANGE)
    ) {
      return;
    }
    const isModeCompare = !!dateRange?.selection2;
    const filterOptions = {
      qrIds: selectedQrs,
      location: selectedCountry,
      device: selectedDevice,
      isCompareMode: isModeCompare,
      periodStart: dateRange.selection1.startDate
        ? moment(dateRange.selection1.startDate).startOf('D').toDate()
        : null,
      periodEnd: dateRange.selection1.endDate
        ? moment(dateRange.selection1.endDate).endOf('D').toDate()
        : null,
      secondPeriodStart: isModeCompare
        ? moment(dateRange.selection2.startDate).endOf('D').toDate()
        : null,
      secondPeriodEnd: isModeCompare
        ? moment(dateRange.selection2.endDate).endOf('D').toDate()
        : null,
      mode: isModeCompare ? 'compare' : 'period',
      isFitersActive: true
    };
    dispatch(setFilters(filterOptions));
    dispatch(setFolderId(selectedFolderId));
  };

  useEffect(() => {
    if (!isFitersActive) {
      setSelectedDevice('');
      setSelectedCountry('');
      setDateRange(START_DATE_RANGE);
    }
  }, [isFitersActive]);

  const handleShowLoader = (isShow) => {
    setShowLoader(isShow);
  };

  return (
    <>
      {noUser ? <RegisterBlock /> : <Loader opacity={50} toggle={showLoader} />}
      <section>
        <div className="bg-secondary p-3 p-lg-4">
          <Row>
            <Col>
              <div className="d-flex justify-content-between alignt-itens-center">
                <div className="fs-4 fw-bold position-relative me-3">
                  {t('filters.filters')}
                  {isFitersActive && (
                    <div
                      className="position-absolute rounded-circle bg-primary"
                      style={{ width: 10, height: 10, right: -10, top: 5 }}
                    />
                  )}
                </div>
                <div className="d-flex">
                  <ExportCsv handleShowLoader={handleShowLoader} />
                  {isFitersActive && (
                    <div
                      className="text-dark d-flex align-items-center ms-1 ms-sm-4"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        dispatch(resetFilters());
                        dispatch(setFolderId(null));
                        const url = new URL(window.location.href);
                        url.searchParams.delete('id');
                        window.history.replaceState(
                          {},
                          document.title,
                          url.toString()
                        );
                      }}
                    >
                      <BsXCircle className="me-2" />
                      {t('filters.clear')}
                    </div>
                  )}
                </div>
              </div>

              {maxPage > 1 && <PaginationMeQr />}

              <Row className="gy-2 mt-2">
                <CustomCol xs={12}>
                  <QrFilter
                    selectedQrs={selectedQrs}
                    setSelectedQrs={setSelectedQrs}
                    setSelectedFolderId={setSelectedFolderId}
                  />
                </CustomCol>

                <CustomCol xs={12}>
                  <DateFilter
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </CustomCol>

                <CustomCol xs={12}>
                  <LocationFilter
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                  />
                </CustomCol>

                <CustomCol xs={12}>
                  <DeviceFilter
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                  />
                </CustomCol>

                <CustomCol xs={12}>
                  <Button className="w-100 h-100 py-3" onClick={handleApply}>
                    {t('filters.applyFilters')}
                  </Button>
                </CustomCol>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Filters;

export const CustomCol = styled(Col)`
  @media (min-width: 992px) {
    width: 20%;
  }
`;
