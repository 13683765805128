import { Col, Row } from 'react-bootstrap';
import DeviceChart from '../../charts/DeviceChart';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmptyDeviceChart from '../../charts/EmptyDeviceChart';

const COUNT_DATA = 5;

const Device = () => {
  const {
    filters,
    isCompareMode,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    filters: state.filters,
    isCompareMode: state.filters.isCompareMode,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const [deviceData, setDeviceData] = useState([]);
  const [deviceData2, setDeviceData2] = useState([]);

  const { getDevices } = useRequestLocation();
  useEffect(() => {
    getDevices({ startData: periodStart, endData: periodEnd }).then((data) => {
      setDeviceData(getDeviceCount(data));
    });
    if (isCompareMode) {
      getDevices({
        startData: secondPeriodStart,
        endData: secondPeriodEnd
      }).then((data) => {
        setDeviceData2(getDeviceCount(data));
      });
    }
    // eslint-disable-next-line
  }, [filters, page]);

  const getDeviceCount = (data) => {
    const DeviceType = data
      .map((item) => ({
        vendor: item.key || t('devices.unknown'),
        count: item.doc_count
      }))
      .reduce((acc, { vendor, count }) => {
        acc[vendor] = (acc[vendor] || 0) + count;
        return acc;
      }, {});
    const sortedData = Object.entries(DeviceType).sort((a, b) => b[1] - a[1]);
    if (sortedData.length > COUNT_DATA) {
      const otherData = sortedData
        .slice(COUNT_DATA)
        .reduce((acc, item) => (acc += item[1]), 0);
      return [...sortedData.slice(0, COUNT_DATA), ['Other', otherData]];
    }
    return sortedData;
  };

  if (isCompareMode) {
    return (
      <Col xs={12}>
        <Row className="justify-content-between align-items-center mb-4">
          <Col xs={12}>
            <div className="fw-bold fs-5 text-capitalize">
              {t('devices.device')}
            </div>
          </Col>
          <Col xs={12}>
            <div className="fs-7 text-gray">
              {t('devices.deviceDescription')}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-4 gy-3">
          <Col xs={12} lg={6}>
            {deviceData2.length ? (
              <DeviceChart
                data={deviceData2}
                color={'device'}
                title={t('devices.previous')}
              />
            ) : (
              <EmptyDeviceChart />
            )}
          </Col>
          <Col xs={12} lg={6}>
            {deviceData.length ? (
              <DeviceChart
                data={deviceData}
                color={'device'}
                title={t('devices.selected')}
              />
            ) : (
              <EmptyDeviceChart />
            )}
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col xs={12} lg={6}>
      <Row className="justify-content-between align-items-center mb-3">
        <Col xs={12}>
          <div className="fw-bold fs-5 text-capitalize">
            {t('devices.device')}
          </div>
        </Col>
        <Col xs={12}>
          <div className="fs-7 text-gray">{t('devices.deviceDescription')}</div>
        </Col>
      </Row>
      {deviceData.length ? (
        <DeviceChart data={deviceData} color={'device'} />
      ) : (
        <EmptyDeviceChart />
      )}
    </Col>
  );
};

export default Device;
