import { useDispatch, useSelector } from 'react-redux';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { setPaginationPage } from '../../../redux/slices/meQrData';

const PaginationMeQr = () => {
  const page = useSelector((state) => state.meQrData.pagination.page);
  const maxPage = useSelector((state) => state.meQrData.pagination.maxPage);

  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-end fs-1">
      <PaginationControl
        page={page}
        between={3}
        total={maxPage}
        limit={1}
        changePage={(page) => {
          dispatch(setPaginationPage(page));
        }}
        ellipsis={1}
      />
    </div>
  );
};

export default PaginationMeQr;
