import moment from 'moment';
import { DAY, MONTH, YEAR } from '../../constants';
import { colors, solidBlueColor } from '../../constants/colors';
import formatNumber from './FormatNumber';

export default function ViewsAndSalesChartService(fullData, data, activeButton, type, t, currency='') {
  const formattedData = {
    labels: [],
    datasets: []
  };

  const allDates = Object.keys(data).flatMap(event => Object.keys(data[event]));
  const validDates = allDates.filter(date => date !== 'null');

  const minDate = validDates.length > 0 ? new Date(Math.min(...validDates.map(date => new Date(date)))) : null;
  const maxDate = validDates.length > 0 ? new Date(Math.max(...validDates.map(date => new Date(date)))) : null;

  const selectedDates = [];
  const selectedMonths = new Set();
  const selectedYears = new Set();

  if (minDate && maxDate) {
    const currentDate = new Date(minDate);

    while (currentDate <= maxDate) {
      selectedDates.push(new Date(currentDate).toISOString());

      const formattedMonth = moment(currentDate).format("YYYY-MM");
      selectedMonths.add(formattedMonth);

      const formattedYear = moment(currentDate).format("YYYY");
      selectedYears.add(formattedYear);

      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else {
    selectedDates.push(...validDates);

    selectedMonths.add(...validDates.map(date => moment(date).format("YYYY-MM")));
    selectedYears.add(...validDates.map(date => moment(date).format("YYYY")));
  }

  const formattedLabels = selectedDates.map(date => moment(date).format("ddd D, YYYY"));

  const formattedMonthLabels = [...selectedMonths].map(month => moment(month + "-01").format("MMMM YYYY"));
  const formattedYearLabels = [...selectedYears].map(year => moment(year + "-01-01").format("YYYY"));

  if (activeButton === DAY) {
    formattedData.labels = formattedLabels;
  } else if (activeButton === YEAR) {
    formattedData.labels = formattedYearLabels
  } else if (activeButton === MONTH) {
    formattedData.labels = formattedMonthLabels
  }

  Object.keys(data).forEach((event, index) => {
    const eventData = data[event];

    const eventTypeData = selectedDates.map(date => {
      if (activeButton === DAY) {

        return eventData[date] ? eventData[date][type] : null;
      } else if (activeButton === MONTH || activeButton === YEAR) {
        const aggregationKey = activeButton === 'month' ? moment(date).format('YYYY-MM') : moment(date).format('YYYY');

        return selectedDates.reduce((sum, currentDate) => {
          const currentKey = activeButton === MONTH ? moment(currentDate).format('YYYY-MM') : moment(currentDate).format('YYYY');
          return currentKey === aggregationKey && eventData[currentDate] ? sum + eventData[currentDate][type] : sum;
        }, 0);
      }
      return null;
    });

    const total = currency + formatNumber(Object.values(eventData).reduce((acc,el) => acc+el[type] ,0))
    const color = Object.keys(data).length === 1 && type === 'views' ? solidBlueColor: colors[(index) % colors.length].bright
    formattedData.datasets.push({
      label: fullData.singleEventsTicketsStatistic ?
        Object.keys(data).length === 1 && type === 'views' ? `${t('ticketStatistic.totalViews')} (${total})`:
          `${fullData.tickets[event]} (${total})`: `${fullData.events[event]} (${total})`,
      data: eventTypeData,
      backgroundColor: color,
      fill: true,
      lineTension: 0.1,
      borderColor: color,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: color,
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 10,
    });
  });
  return formattedData
}