import { useTranslation } from 'react-i18next';
import CalendarIcon from './svg/CalendarIcon';

const DefaultScans = ({ scans }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-gray fs-7 d-flex align-items-center text-capitalize">
        <span className="me-2 pb-1">
          <CalendarIcon />
        </span>
        {t('scans.all')}
      </div>
      <div className="fw-bold fs-2">{scans}</div>
    </>
  );
};

export default DefaultScans;
