import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';



const Tip = () => {
  const { t } = useTranslation()
  return (
    <Col xs={12} className='mb-4'>
      <div className="d-flex gap-2 py-2 px-3 rounded bg-violet-light text-violet-hover">
        <i className="bi bi-info-circle"></i>
        <p
          className="fs-7 mb-0">{t('booking.tip')}</p>
      </div>
    </Col>
  );
};

export default Tip;