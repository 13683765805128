import { Col, Row } from 'react-bootstrap';
import CountriesItem from './CountriesItem';
import CountryCityCount from './CountryCityCount';
import CountryTable from './CountryTable';
import CountryMap from './СountryMap';
import CloseIcon from './svg/CloseIcon';
import CountrySelect from './CountrySelect';
import { useDispatch, useSelector } from 'react-redux';
import CitySelect from './CitySelect';
import { deleteCity, deleteCountry } from '../../../redux/slices/locationScans';
import CountryTableSelected from './CountryTableSelected';
import { useTranslation } from 'react-i18next';

const ScansLocation = () => {
  const { country, cities, isCompareMode } = useSelector((state) => ({
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    isCompareMode: state.filters.isCompareMode
  }));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteCity = (city) => {
    dispatch(deleteCity(city));
  };

  const handleDeleteCountry = () => {
    dispatch(deleteCountry());
  };

  return (
    <Col xs={12}>
      <div className="p-3 p-lg-4 bg-white h-100">
        <Row className="mb-3">
          <Col xs="auto">
            <div className="fw-bold fs-4 text-capitalize">
              {t('location.location')}
            </div>
            <div className="fs-7 text-gray">{t('location.description')}</div>
          </Col>
        </Row>
        <Row className="mt-2">
          <CountryCityCount />
        </Row>
        <div className="d-flex mb-3 mt-3">
          <CountrySelect />
          <CitySelect />
        </div>
        <div className="d-flex mb-3">
          {country && (
            <>
              <div>{t('filters.filters')}:</div>
              <div className="ps-2 bg-darkgray d-flex gap-2 align-items-center mx-1 rounded-1">
                <small>{country}</small>
                <div
                  className="cursor-pointer h-100 px-1 d-flex align-items-center"
                  onClick={handleDeleteCountry}
                >
                  <CloseIcon className="cursor-pointer" />
                </div>
              </div>
            </>
          )}
          {cities.map((item) => {
            return (
              <div
                key={item}
                className="ps-2 bg-darkgray d-flex gap-2 align-items-center mx-1 rounded-1"
              >
                <small>{item}</small>
                <div
                  className="cursor-pointer h-100 px-1 d-flex align-items-center"
                  onClick={() => handleDeleteCity(item)}
                >
                  <CloseIcon className="cursor-pointer" />
                </div>
              </div>
            );
          })}
        </div>
        {isCompareMode ? (
          <Row className="mb-lg-5 gy-4">
            <Col xs={12} lg={6}>
              <div className="fw-bold fs-5 mb-4">{t('devices.previous')}</div>
              <Col xs={12}>
                <div className="mt-2 mb-2 mb-lg-4" style={{ maxHeight: 500 }}>
                  <CountriesItem modeCompare={true} />
                </div>
              </Col>
              <Col xs={12}>
                <CountryTable rows={10} modeCompare={true} />
              </Col>
            </Col>

            <Col xs={12} lg={6}>
              <div className="fw-bold fs-5 mb-4">{t('devices.selected')}</div>
              <Col xs={12}>
                <div className="mt-2 mb-2 mb-lg-4" style={{ maxHeight: 500 }}>
                  <CountriesItem />
                </div>
              </Col>
              <Col xs={12}>
                <CountryTableSelected />
              </Col>
            </Col>
          </Row>
        ) : (
          <Row className="mb-5 gy-4 ">
            <Col xs={12} lg={6}>
              <div className="mt-2" style={{ maxHeight: 500 }}>
                <CountriesItem />
              </div>
            </Col>
            <Col xs="auto" lg={6}>
              <CountryTable />
            </Col>
          </Row>
        )}

        <CountryMap />
      </div>
    </Col>
  );
};

export default ScansLocation;
