export const LANGUAGES_ARRAY = [
  'ru',
  'en',
  'es',
  'pt',
  'ms',
  'vi',
  'fr',
  'de',
  'ar',
  'id',
  'th',
  'ko',
  'ja',
  'nl',
  'it',
  'sv',
  'uk',
  'zh',
  'he',
  'hi',
  'pl'
];
