import { Row } from 'react-bootstrap';
import Title from '../../components/blocks/title/mePosTitle';
import Records from '../../components/blocks/records/Records';
import MePosFilters from '../../components/blocks/filters/mePosFilters';
import { useSelector } from 'react-redux';
import ShortStatistic from '../../components/blocks/statistic';
import RevenueTrade from '../../components/blocks/records/RevenueTrade';
import RecordsServiceStat from '../../components/blocks/records/RecordsServiceStat';
import RecordMasterStat from '../../components/blocks/records/RecordMasterStat';
import MasterFinishingStat from '../../components/blocks/records/MasterFinishingStat';
import ServiceFinishingStat from '../../components/blocks/records/ServiceFinishingStat';
import DataContainer from '../../components/blocks/pagination/DataContainer';
import { useEffect, useRef, useState } from 'react';
import { ScrollButton } from '../../components/scroll/mePosScroll/ScrollButton';
import Tip from '../../components/blocks/tip';
import HideFiltersButton from '../../components/blocks/filters/mePosFilters/HideFiltersButton';

const MePosPage = () => {
  const statisticType = useSelector(state => state.filters.statisticType)
  const mePosContainer = useRef(null)
  const [showScrollButton, setShowScrollButton] = useState(false)

  const handleScrollClick = () => {
    if (window.scrollY && mePosContainer && mePosContainer.current) {
      mePosContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      })
    }
  }

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setShowScrollButton(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='me-pos' ref={mePosContainer}>
      <Row className="d-flex flex mt-3 justify-content-center main-view">
        <Tip />
        <div className="d-flex justify-content-between mb-5">
          <Title />
          <HideFiltersButton showFilters={showFilters} setShowFilters={setShowFilters} />
        </div>
        {showFilters &&
          <MePosFilters />
        }
        <ShortStatistic/>
        {statisticType === 'DAILY_STATISTIC' ? (
          <>
            <Records />
            <RevenueTrade />
          </>
        ) : statisticType === 'POPULAR_SERVICE' ? (
          <>
            <RecordsServiceStat />
          </>
        ) : statisticType === 'POPULAR_MASTER' ? (
          <>
            <RecordMasterStat />
          </>
        ) : statisticType === 'COMPLETE_MASTER' ? (
          <>
            <MasterFinishingStat />
          </>
        ) : statisticType === 'COMPLETE_SERVICE' ? (
          <>
            <ServiceFinishingStat />
          </>
        ) : (
          <></>
        )}
        <DataContainer />
      </Row>
      <ScrollButton handleClick={handleScrollClick} toShow={showScrollButton} />
    </div>
  );
};

export default MePosPage;
