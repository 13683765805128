import Wrapper from './components/wrapper';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { LANGUAGES_ARRAY } from './constants/languages';

const base = window.location.pathname.split('/')[1];
const rightPathname = LANGUAGES_ARRAY.includes(base);

const App = () => {
  return (
    <>
      <BrowserRouter basename={rightPathname ? `${base}` : ``}>
        <Wrapper />
      </BrowserRouter>
    </>
  );
};

export default App;
