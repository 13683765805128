const CloseIcon = (props) => (
  <svg
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.165.165c.22-.22.576-.22.795 0l2.978 2.977L6.915.165A.563.563 0 0 1 7.71.96L4.733 3.938 7.71 6.915a.563.563 0 0 1-.795.795L3.937 4.733.96 7.71a.563.563 0 1 1-.795-.795l2.977-2.978L.165.96a.563.563 0 0 1 0-.795Z"
      fill="#3E4957"
    />
  </svg>
);

export default CloseIcon;
