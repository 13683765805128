import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DAY, MONTH, YEAR } from '../../../constants';
import LineChart from '../../charts/ticket/LineChart';
import { useEffect, useState } from 'react';
import ViewsAndSalesChartService from '../../../services/MeTicketAdditional/ViewsAndSalesChartService';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';

function ViewsChart() {
  const {t} = useTranslation()
  const [activeButton, setActiveButton] = useState(YEAR)

  const [formattedGraphicData, setFormattedGraphicData] = useState({
    labels: [],
    datasets: []
  })

  const filters = useSelector(state => state.filters)

  const {getEventsFilters} = useRequestLocation()

  useEffect(() => {
    getEventsFilters(filters.periodStart, filters.periodEnd, filters.eventsArray).then(fullData => {
      if (fullData) {
        const data = fullData.salesAndViewsDiagrams
        const type = 'views'
        const formattedData = ViewsAndSalesChartService(fullData, data, activeButton, type, t)
        if (!formattedData.datasets.length) {
          formattedData.datasets.push('-1')
          formattedData.labels = [""]
        }
        setFormattedGraphicData(formattedData)
      }
    })
  }, [activeButton, filters])

  return (
    <Col xs={12} lg={12} className={"border rounded-1"}>
      <Col xs={12} lg={12}
           className={`p-3 ${formattedGraphicData.datasets.length > 0 && formattedGraphicData.datasets[0] !== "-1" ? '' :'opacity-25'}`}>
        <Row className="justify-content-between">
          <Col xs={12} lg={'auto'}>
            <div className="fw-bold fs-4 mb-2 text-capitalize">
              {t('ticketStatistic.viewsChart')}
            </div>
          </Col>
          <Col xs={12} lg={'auto'}>
            <ButtonGroup className="w-100 w-lg-auto">
              <Button
                active={activeButton === YEAR}
                onClick={() => setActiveButton(YEAR)}
                variant="outline-lightGray"
              >
                {t('ticketStatistic.year')}
              </Button>
              <Button
                active={activeButton === MONTH}
                onClick={() => setActiveButton(MONTH)}
                variant="outline-lightGray"
              >
                {t('ticketStatistic.month')}
              </Button>
              <Button
                active={activeButton === DAY}
                onClick={() => setActiveButton(DAY)}
                variant="outline-lightGray"
              >
                {t('ticketStatistic.day')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <LineChart
            formattedData={formattedGraphicData}
            additionalClass="col-12"
          />
        </Row>
      </Col>
    </Col>
  );
}

export default ViewsChart