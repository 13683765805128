import Filters from '../../../components/blocks/filters/meTicketFilters';
import TicketsNoData from '../../../components/blocks/info/TicketsNoData';
import SellsStat from '../../../components/blocks/ticketStat/SellStat';
import SalesChart from '../../../components/blocks/ticketCharts/SalesChart';
import ViewsStat from '../../../components/blocks/ticketStat/ViewsStat';
import ViewsChart from '../../../components/blocks/ticketCharts/ViewsChart';
import TicketTypeSales from '../../../components/blocks/ticketCharts/TicketTypeSales';
import ReturnsStat from '../../../components/blocks/ticketStat/ReturnsStat';
import AvgPriceStat from '../../../components/blocks/ticketStat/AvgPriceStat';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import EmptyContainer from '../EmptyContainer';

export default function StatisticsContainer() {
  const [fullData, setFullData] = useState(undefined)
  const {getEventsFilters} = useRequestLocation()

  useEffect(() => {
    getEventsFilters().then(fullData => setFullData(fullData !== null))
  }, []);
  return (
    fullData !== null ?
    <>
      <Filters />
      <TicketsNoData/>
      <div className="col-md-6">
        <div className="row">
          <div className="col-12">
            <SellsStat />
          </div>
          <div className="col-12">
            <SalesChart />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-12">
            <ViewsStat />
          </div>
          <div className="col-12">
            <ViewsChart />
          </div>
        </div>
      </div>
      <TicketTypeSales />
      <ReturnsStat />
      <AvgPriceStat />
    </> :
      <EmptyContainer/>
  );
};