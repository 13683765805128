const grayBackground = {
  backgroundColor: '#F5f5f5',
  color: 'white',
  height: '24px',
  margin: '8px',
  borderRadius: '4px'
};
const rowStyle = {
  borderBottomWidth: '1px',
  borderColor: '#dee2e6'
};

const EmptyTable = ({ emptyData }) => {
  return (
    <table className="w-100">
      <tbody>
        {emptyData.map((row) => (
          <tr key={row.id} style={rowStyle}>
            {Array.from({ length: Object.keys(emptyData[0]).length - 1 }).map(
              (_, i) => (
                <td key={i}>
                  <div style={grayBackground}></div>
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmptyTable;
