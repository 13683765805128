import { useState } from 'react';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsBuilding, BsGeoAlt } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';

const CountryCityCount = () => {
  const { t } = useTranslation();
  const { filters, page } = useSelector((state) => ({
    filters: state.filters,
    page: state.meQrData.pagination.page
  }));
  const [countries, setCountries] = useState(0);
  const [cities, setCities] = useState(0);
  const { getCoutryCityCount } = useRequestLocation();

  useEffect(() => {
    getCoutryCityCount().then((data) => {
      setCountries(data.countryCount);
      setCities(data.cityCount);
    });
    // eslint-disable-next-line
  }, [filters, page]);

  return (
    <>
      <Col xs={6} lg="auto" className="text-gray d-flex align-items-baseline">
        <BsGeoAlt className="me-2" /> {t('location.countries')}:
        <span className="text-primary ms-2">{countries}</span>
      </Col>
      <Col xs={6} lg="auto" className="text-gray d-flex align-items-baseline">
        <BsBuilding className="me-2" /> {t('location.cities')}:
        <span className="text-primary ms-2">{cities}</span>
      </Col>
    </>
  );
};

export default CountryCityCount;
