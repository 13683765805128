import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import moment from 'moment/moment';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { addDays, isAfter } from 'date-fns';

const MePosDateFilter = ({ dateRange, setDateRange }) => {
  const { t } = useTranslation();

  const [isDropDown, setIsDropDown] = useState(false);
  const [selectTitle, setSelectTitle] = useState(
    `${moment().subtract(1, 'week').format('DD.MM.YY')} 
    - 
    ${moment().format('DD.MM.YY')}`);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const ref = useRef(null);
  useOnClickOutside(ref, () => handleClose());
  const [errorLabel, setErrorLabel] = useState('')

  const handleClose = () => {
    setIsDropDown(false);
  };

  const updateRange = (count, period) => {
    setDateRange({
      selection1: {
        startDate: moment().startOf('D').subtract(count, period).toDate(),
        endDate: moment().endOf('D').toDate(),
        key: 'selection1'
      }
    });
  };

  const handleApply = () => {
    if (dateRange.selection1.startDate !== null && dateRange.selection1.endDate!==null) {
      setSelectTitle(
        `${moment(dateRange.selection1.startDate).format('DD.MM.YY')} 
      - 
      ${moment(dateRange.selection1.endDate).format('DD.MM.YY')}`
      );
    }
    handleClose();
  };


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isDropDown) {
      const date = `${moment(dateRange.selection1.startDate).format('DD.MM.YY')}
      - 
      ${moment(dateRange.selection1.endDate).format('DD.MM.YY')}`
      if (date !== selectTitle) {
        setSelectTitle(date)
      }
    }
  },[dateRange, isDropDown])

  return (
    <div className="position-relative py-2 border rounder-4px">
      <DropField>
        <Form
          onClick={() => setIsDropDown(!isDropDown)}
          className="overflow-hidden"
        >
          <Dropdown.Toggle className="button form-select border-0 shadow-none select-input d-flex justify-content-between"
          style={{backgroundImage: 'none'}}>
              {selectTitle}
              <i className='bi bi-calendar4-week'></i>

          </Dropdown.Toggle>
        </Form>
        {isDropDown && (
          <MenuDropdown
            ref={ref}
            className="bg-white position-absolute border border-1 rounded-1 py-2"
          >
            <div className="d-block d-lg-flex">
              <MenuItems className="overflow-hidden border-end">
                <DropdownElem onClick={() => updateRange(6, 'd')}>
                  {t('filters.7days')}
                </DropdownElem>
                <DropdownElem onClick={() => updateRange(1, 'M')}>
                  {t('filters.month')}
                </DropdownElem>
                {/*<DropdownElem onClick={() => updateRange(1, 'y')}>*/}
                {/*  {t('filters.year')}*/}
                {/*</DropdownElem>*/}
                {/*<DropdownElem onClick={(e) => updateRange(2, 'W')}>*/}
                {/*  {t('filters.custom')}*/}
                {/*</DropdownElem>*/}
                <hr className="d-lg-none my-2" />
              </MenuItems>
              <div>
                <RangeContainer className="d-flex d-lg-block justify-content-center">
                  <DateRangePicker
                    onChange={(item) => {
                      const { selection1 } = item;
                      if (isAfter(selection1.startDate, new Date())) {
                        item.selection1.startDate = new Date();
                      }

                      if (isAfter(selection1.endDate, new Date())) {
                        item.selection1.endDate = new Date();
                      }

                      const maxRange = addDays(selection1.startDate, 90);
                      if (isAfter(selection1.endDate, maxRange)) {
                        item.selection1.endDate = maxRange;
                        setErrorLabel(t('filters.calendWarning'))
                      } else {
                        setErrorLabel('')
                      }

                      setDateRange({ ...dateRange, ...item });
                    }}
                    months={2}
                    ranges={[dateRange.selection1]}
                    rangeColors={['#F5E7F8', '#B3AEF0']}
                    direction={screenWidth > 991 ? 'horizontal' : 'vertical'}
                    showDateDisplay={false}
                  />
                </RangeContainer>
                <hr className="my-2" />
                <div className="d-flex justify-content-center flex-column flex-lg-row align-items-center justify-content-lg-between px-3 pb-2">
                  {dateRange.selection1.startDate &&
                  dateRange.selection1.startDate ? (
                    <small className="d-block">
                      {`${moment(dateRange.selection1.startDate).format(
                        'MMMM DD, YYYY'
                      )} -
                  ${moment(dateRange.selection1.endDate).format(
                        'MMMM DD, YYYY'
                      )}`}
                    </small>
                  ) : (
                    <small>{t('filters.allPeriod')}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="border-top p-3 pb-2">
              <div className='d-flex justify-content-between align-items-center'>
                <div className='opacity-50' style={{color:'red'}}>
                  {errorLabel}
                </div>
                <div className="d-flex col-6 justify-content-end g-3 gap-2">
                  <Col xs={12} lg={6}>
                    <button
                      className="w-100 d-flex justify-content-center btn clear-btn"
                      onClick={handleClose}
                    >
                      <small>{t('filters.cancel')}</small>
                    </button>
                  </Col>
                  <Col xs={12} lg={6}>
                    <button
                      className="w-100 d-flex justify-content-center btn apply-btn"
                      onClick={handleApply}
                    >
                      <small>{t('filters.apply')}</small>
                    </button>
                  </Col>
                </div>
              </div>

            </div>
          </MenuDropdown>
        )}
      </DropField>
    </div>
  );
};

export default MePosDateFilter;


const DropField = styled(Dropdown)`
  height: 100%;
  .btn.show {
    color: #3e4957;
    background-color: white;
  }
  .dropdown-menu.show {
    /* width: 100%; */
    /* max-height: 300px;
    overflow: scroll; */
  }
  .select-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3e4957;
    background-color: white;
    border: 1px solid #ced4da;
    &::after {
      display: none;
    }

    &:hover {
      color: #3e4957;
      background-color: white;
    }
  }
`;

const RangeContainer = styled.div`
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrCalendarWrapper {
    width: 100% !important;
  }
  button {
    border: none;
  }
  .rdrMonth {
    width: 22.667em;
    padding-bottom: 0px;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    height: 14px;
  }
  .rdrDay {
    height: 2.4em;
  }
  [class*='rdrStartEdge'][style='color: rgb(245, 231, 248);'],
  [class*='rdrEndEdge'][style='color: rgb(245, 231, 248);'] {
    background-color: #796fef;
  }
  [class*='rdrInRange'][style='color: rgb(245, 231, 248);'] + span > span,
  [class*='rdrInRange'][style='color: rgb(179, 174, 240);'] + span > span {
    color: #1d2429 !important;
  }
  [class*='rdrInRange'][style='color: rgb(245, 231, 248);']
    + span
    + span
    > span,
  [class*='rdrInRange'][style='color: rgb(179, 174, 240);']
    + span
    + span
    > span {
    color: #1d2429 !important;
  }
  [class*='rdrStartEdge'][style='color: rgb(179, 174, 240);'],
  [class*='rdrEndEdge'][style='color: rgb(179, 174, 240);'] {
    background-color: #796fef;
  }
`;

const DropdownElem = styled.div`
  padding: 4px 16px;
  &:hover {
    background: #e9ecef;
  }
`;
const MenuItems = styled.div`
  width: 216px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const MenuDropdown = styled.div`
  z-index: 500;
  @media (max-width: 991px) {
    width: 100%;
  }
`;