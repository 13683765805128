import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { Button, Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const DateFilter = ({ dateRange, setDateRange }) => {
  const { t } = useTranslation();
  const [isDropDown, setIsDropDown] = useState(false);
  const [selectTitle, setSelectTitle] = useState(
    `${moment().subtract(1, 'week').format('MMM DD, YYYY')} 
    - 
    ${moment().format('MMM DD, YY')}`
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const ref = useRef(null);
  useOnClickOutside(ref, () => handleClose());

  const handleClose = () => {
    setIsDropDown(false);
  };

  const updateRange = (count, period) => {
    setDateRange({
      selection1: {
        startDate: moment().startOf('D').subtract(count, period).toDate(),
        endDate: moment().endOf('D').toDate(),
        key: 'selection1'
      }
    });
  };

  const updateAllTimeRange = () => {
    setDateRange({
      selection1: {
        startDate: null,
        endDate: moment().endOf('D').toDate(),
        key: 'selection1'
      }
    });
  };


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isDropDown) {
      if (!dateRange.selection1.startDate) {
        setSelectTitle(t('filters.all'));
        return;
      }
      const startDate = moment(dateRange.selection1.startDate).clone()
      const endDate = moment(dateRange.selection1.endDate).clone().startOf('day')
      const today = moment().startOf('day')
      if (today.isSame(endDate) && today.isSame(startDate)) {
        setSelectTitle(`${t('filters.today')}: ${startDate.format('MMM DD, YYYY')}`)
        return;
      } else if (startDate.isSame(endDate)) {
        setSelectTitle(startDate.format('MMM DD, YYYY'))
        return;
      }
      const date = `${moment(dateRange.selection1.startDate).format(
        'MMM DD, YYYY'
      )}
      -
      ${moment(dateRange.selection1.endDate).format('MMM DD, YYYY')}`;
      if (date !== selectTitle) {
        setSelectTitle(date);
      }
    }
  }, [dateRange, isDropDown, selectTitle, t]);

  return (
    <div className="position-relative">
      <DropField>
        <FloatingLabel
          label={t('filters.period')}
          onClick={() => setIsDropDown(!isDropDown)}
          className="overflow-hidden"
        >
          <Dropdown.Toggle variant='white' className="form-select border-0 shadow-none text-capitalize">
            {selectTitle}
          </Dropdown.Toggle>
        </FloatingLabel>
        {isDropDown && (
          <MenuDropdown
            ref={ref}
            className="bg-white position-absolute border border-1 rounded-1 py-2"
          >
            <div className="d-block d-lg-flex">
              <MenuItems className="overflow-hidden border-end">
                <DropdownElem onClick={() => updateRange(0, 'd')}>
                  {t('filters.today')}
                </DropdownElem>
                <DropdownElem onClick={() => updateRange(6, 'd')}>
                  {t('filters.7days')}
                </DropdownElem>
                <DropdownElem onClick={() => updateRange(1, 'M')}>
                  {t('filters.month')}
                </DropdownElem>
                <DropdownElem onClick={() => updateRange(1, 'y')}>
                  {t('filters.year')}
                </DropdownElem>
                <DropdownElem onClick={updateAllTimeRange}>
                  {t('filters.all')}
                </DropdownElem>
                <DropdownElem onClick={(e) => updateRange(2, 'W')}>
                  {t('filters.custom')}
                </DropdownElem>
                <hr className="d-lg-none my-2" />
              </MenuItems>
              <div>
                <RangeContainer className="d-flex d-lg-block justify-content-center">
                  <DateRangePicker
                    onChange={(item) => setDateRange({ ...dateRange, ...item })}
                    months={2}
                    ranges={
                      [dateRange.selection1]
                    }
                    rangeColors={['#F5E7F8', '#B3AEF0']}
                    direction={screenWidth > 991 ? 'horizontal' : 'vertical'}
                    showDateDisplay={false}
                  />
                </RangeContainer>
                <hr className="my-2" />
                <div className="d-flex justify-content-center flex-column flex-lg-row align-items-center justify-content-lg-between px-3 pb-2">
                  {dateRange.selection1.startDate &&
                  dateRange.selection1.startDate ? (
                    <small className="d-block">
                      {`${moment(dateRange.selection1.startDate).format(
                        'MMMM DD, YYYY'
                      )} -
                  ${moment(dateRange.selection1.endDate).format(
                        'MMMM DD, YYYY'
                      )}`}
                    </small>
                  ) : (
                    <small>{t('meTicketFilters.allPeriod')}</small>
                  )}

                </div>
              </div>
            </div>
            <div className="border-top p-3 pb-2">
              <Row className="justify-content-end g-3">
                <Col xs={12} lg={3}>
                  <Button
                    className="w-100 d-flex justify-content-center"
                    onClick={handleClose}
                  >
                    <small>{t('filters.cancel')}</small>
                  </Button>
                </Col>
                <Col xs={12} lg={3}>
                  <Button
                    className="bg-primary text-white w-100 d-flex justify-content-center"
                    onClick={handleClose}
                  >
                    <small>{t('filters.apply')}</small>
                  </Button>
                </Col>
              </Row>
            </div>
          </MenuDropdown>
        )}
      </DropField>
    </div>
  );
};

export default DateFilter;

const DropField = styled(Dropdown)`
  height: 100%;
  .btn.show {
    color: #3e4957;
    background-color: white;
  }
  .dropdown-menu.show {
    /* width: 100%; */
    /* max-height: 300px;
    overflow: scroll; */
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3e4957;
    background-color: white;
    border: 1px solid #ced4da;
    &::after {
      display: none;
    }

    &:hover {
      color: #3e4957;
      background-color: white;
    }
  }
`;

const RangeContainer = styled.div`
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrCalendarWrapper {
    width: 100% !important;
  }
  button {
    border: none;
  }
  .rdrMonth {
    width: 22.667em;
    padding-bottom: 0px;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    height: 14px;
  }
  .rdrDay {
    height: 2.4em;
  }
  [class*='rdrStartEdge'][style='color: rgb(245, 231, 248);'],
  [class*='rdrEndEdge'][style='color: rgb(245, 231, 248);'] {
    background-color: #16B054;
  }
  [class*='rdrInRange'][style='color: rgb(245, 231, 248);'] + span > span,
  [class*='rdrInRange'][style='color: rgb(179, 174, 240);'] + span > span {
    color: #1d2429 !important;
  }
  [class*='rdrInRange'][style='color: rgb(245, 231, 248);']
    + span
    + span
    > span,
  [class*='rdrInRange'][style='color: rgb(179, 174, 240);']
    + span
    + span
    > span {
    color: #1d2429 !important;
  }
  [class*='rdrStartEdge'][style='color: rgb(179, 174, 240);'],
  [class*='rdrEndEdge'][style='color: rgb(179, 174, 240);'] {
    background-color: #796fef;
  }
`;

const DropdownElem = styled.div`
  padding: 4px 16px;
  &:hover {
    background: #e9ecef;
  }
`;
const MenuItems = styled.div`
  width: 216px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const MenuDropdown = styled.div`
  z-index: 500;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
