import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useEffect } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../elements/general';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

const DeviceFilter = ({ selectedDevice, setSelectedDevice }) => {
  const { periodStart, periodEnd, filters, page, loading } = useSelector(
    (state) => ({
      periodStart: state.filters.periodStart,
      periodEnd: state.filters.periodEnd,
      filters: state.filters,
      page: state.meQrData.pagination.page,
      loading: state.preloader.dataLoad.isGetDevicesLoad
    })
  );
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  const { getDevices } = useRequestLocation();
  useEffect(() => {
    if (!isActiveDropdown) {
      return;
    }
    getDevices({ startData: periodStart, endData: periodEnd }).then((data) => {
      setDevices(
        data
          .map((item) => item.key)
          .filter((item) => item !== '')
          .sort()
      );
    });
    // eslint-disable-next-line
  }, [filters, page, isActiveDropdown]);

  useEffect(() => {
    setFilteredDevices(devices);
  }, [devices]);

  const handleDeviceSelect = (device) => {
    setSelectedDevice(device);
    setSearchValue('');
    setFilteredDevices(devices);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filtered = devices.filter((item) =>
      item.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredDevices(filtered);
  };

  return (
    <DropField onClick={() => setIsActiveDropdown(true)}>
      <FloatingLabel label={t('filters.device')}>
        <Dropdown.Toggle className="form-select">
          <div className="me-3 overflow-hidden text-truncate">
            {selectedDevice || t('filters.allDevices')}
          </div>
        </Dropdown.Toggle>
      </FloatingLabel>

      <Dropdown.Menu>
        <Dropdown.Item className="px-1">
          <Form.Control
            className="border-0"
            type="text"
            placeholder={`${t('filters.search')}...`}
            onChange={handleSearch}
            value={searchValue}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => handleDeviceSelect('')}>
          {t('filters.allDevices')}
        </Dropdown.Item>
        {loading ? (
          <div className="d-flex justify-content-center my-2">
            <Spinner animation="border" role="status" className="m-auto" />
          </div>
        ) : (
          filteredDevices.map((item, index) => (
            <Dropdown.Item key={index} onClick={() => handleDeviceSelect(item)}>
              {item}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </DropField>
  );
};

export default DeviceFilter;
