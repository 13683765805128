import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import TypeCircleChart from './TypeCircleChart';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';

function TicketTypeSales() {
  const {t} = useTranslation()

  const [circlesArray, setCirclesArray] = useState([])
  const [titlesData, setTitlesData] = useState([])

  const [totalAmountOfSold,setTotalAmountOfSold] = useState(0)
  const [totalNumberOfTickets, setTotalNumberOfTickets] = useState(0)
  const [title, setTitle] = useState(undefined)
  const [description, setDescription] = useState(undefined)

  const filters = useSelector(state => state.filters)

  const {getEventsFilters} = useRequestLocation()

  useEffect(() => {
    getEventsFilters(null, null, filters.eventsArray).then(data => {
      setTitle(t('ticketStatistic.eventSales'))
      setDescription(t("ticketStatistic.eventSalesDescription"))
      if (data.eventsToTicketsStatistic) {
        const eventsArray = Object.entries(data.eventsToTicketsStatistic).map(([id, event]) =>
          ({ id: parseInt(id), event: event }))
        setTotalAmountOfSold(eventsArray.reduce((acc, el) => acc + el.event.total_sold_tickets, 0))
        setTotalNumberOfTickets(eventsArray.reduce((acc, el) => acc + el.event.total_created_tickets, 0))
        setCirclesArray(eventsArray)
        setTitlesData(data.events)
      } else if (data.singleEventsTicketsStatistic) {
        setTitle(t("ticketStatistic.typeSales"))
        setDescription(t('ticketStatistic.typeSalesDescription'))
        const eventsArray = Object.entries(data.singleEventsTicketsStatistic).map(([id, event]) =>
          ({ id: parseInt(id), event: {
              total_sold_tickets: Object.values(event).reduce((acc, el) => el.sold+acc,0),
              total_created_tickets: Object.values(event).reduce((acc, el) => el.created, 0),
              ticket_left: Object.values(event).reduce((acc, el) => el.created, 0) - Object.values(event).reduce((acc, el) => el.sold+acc,0)
            } }))
        setTotalAmountOfSold(eventsArray.reduce((acc, el) => acc + el.event.total_sold_tickets, 0))
        setTotalNumberOfTickets(eventsArray.reduce((acc, el) => acc + el.event.total_created_tickets, 0))
        setCirclesArray(eventsArray)
        setTitlesData(data.tickets)
      }
    })
  }, [filters.eventsArray])
  return (
    <Col xs={12} lg={12}>
      <div className="border rounded-1 p-4 mb-3 col-12">
        <div className="d-flex justify-content-between ">
          <div>
            <Row>
              <h4>{title}</h4>
            </Row>
            <Row>
              <small className="opacity-50">{description}</small>
            </Row>
          </div>
        </div>
        <Row>
          <div className='d-flex flex-lg-row flex-column'>
            <div className="d-flex">
              <i className="bi bi-cash-coin ps-2 opacity-50 pe-1" style={{fontSize: "14px"}}></i>
              <p>
                <span className="opacity-50 pe-1">{t('ticketStatistic.soldTickets')}:</span>
                <b className="text-primary">{totalAmountOfSold}</b>
              </p>
            </div>
            <div className="d-flex">
              <i className="bi bi-ticket-perforated ps-2 pe-1 opacity-50"></i>
              <p>
                <span className="opacity-50 pe-1">{t('ticketStatistic.totalNumberTickets')}:</span>
                <b className="text-primary">{totalNumberOfTickets}</b>
              </p>
            </div>
          </div>
        </Row>
        <div className="d-flex col justify-content-between flex-wrap">
          {circlesArray.map((el, index) =>
            el.event.total_sold_tickets > 0 &&
            <TypeCircleChart key={index} index={index} eventData={el.event} title={titlesData[el.id]}/>
          )}
        </div>
      </div>
    </Col>
  );
}

export default TicketTypeSales