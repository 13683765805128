import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import useUrlParams from '../../../hooks/useUrlParams';
import { getQrData } from './QrFilter';
import {
  setIsStatisticCollecting,
  setStatisticPath
} from '../../../redux/slices/meQrData';

const INCLUDED_FIELDS = [
  'QR_Code_Name',
  'city',
  'country',
  'latitude',
  'longitude',
  'ip_addr',
  'operation_system',
  'device',
  'os',
  'unique_key',
  'created-at-date'
];

const ExportCsv = ({ handleShowLoader }) => {
  const { isExportCsvActive, maxPage } = useSelector((state) => ({
    isExportCsvActive: state.meQrData.isExportCsvActive,
    maxPage: state.meQrData.pagination.maxPage
  }));
  const { t } = useTranslation();
  const ref = useRef(null);
  const { getCsvData } = useRequestLocation();
  const { baseUrl } = useUrlParams();
  const dispatch = useDispatch();

  const [showPopoverPendingCsv, setShowPopoverPendingCsv] = useState({
    show: false,
    target: null
  });
  const [csvData, setCsvData] = useState([]);

  const handleHidePopup = () => {
    setShowPopoverPendingCsv({
      show: false,
      target: null
    });
  };
  const downloadCsv = async (e, generateNew = 0) => {
    handleShowLoader(true);

    async function getQrDataLoop(page, maxPage, qrData) {
      if (page > maxPage) {
        return qrData;
      }
      const { entries } = await getQrData({ baseUrl, page });
      qrData.push(...entries);
      return getQrDataLoop(page + 1, maxPage, qrData);
    }

    try {
      const csvData = await getCsvData(generateNew);
      if (csvData.status === 'creating' || csvData.status === 'error') {
        dispatch(setIsStatisticCollecting(true));
        setShowPopoverPendingCsv({
          target: e.target,
          show: true
        });
        return;
      } else if (csvData.status === 'pending') {
        dispatch(setStatisticPath(''));
        dispatch(setIsStatisticCollecting(true));
        return;
      }
      const [qrData] = await Promise.all([getQrDataLoop(1, maxPage, [])]);
      const modifiedCsvData = csvData.reduce((acc, item) => {
        const source = item._source;
        const newItem = {
          QR_Code_Name:
            qrData.find((el) => el.entryId === source['entry-id'])
              ?.entryTitle || ''
        };

        Object.keys(source).forEach((el) => {
          if (INCLUDED_FIELDS.includes(el)) {
            newItem[el] = source[el];
          }
        });
        if (!newItem.unique_key) {
          newItem.unique_key = '';
        }
        if (newItem['created-at-date']) {
          newItem['scan_date'] = newItem['created-at-date'];
          delete newItem['created-at-date'];
        }

        acc.push(newItem);
        return acc;
      }, []);

      setCsvData(modifiedCsvData);
    } catch (error) {
    } finally {
      handleShowLoader(false);
    }
  };

  useEffect(() => {
    if (csvData.length) {
      ref.current.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  return (
    <>
      {isExportCsvActive ? (
        <>
          <ButtonToolbar>
            <Button
              variant="link"
              onClick={downloadCsv}
              className="d-flex align-items-center text-decoration-none"
            >
              <BsBoxArrowUpRight className="me-2" />
              {t('filters.export')}
            </Button>
            <CSVLink data={csvData} ref={ref} filename="statistics" />

            <Overlay
              show={showPopoverPendingCsv.show}
              target={showPopoverPendingCsv.target}
              placement="bottom"
            >
              <Popover id="popover-contained" className="p-3">
                <div className="text-center">{t('filters.interrupt')}</div>
                <div className="d-flex gap-2 mt-2">
                  <Button
                    variant="outline-dark"
                    className="w-50"
                    onClick={handleHidePopup}
                  >
                    {t('filters.cancel')}
                  </Button>
                  <Button
                    className="w-50"
                    onClick={() => {
                      handleHidePopup();
                      downloadCsv(null, 1);
                    }}
                  >
                    {t('filters.generate')}
                  </Button>
                </div>
              </Popover>
            </Overlay>
          </ButtonToolbar>
        </>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltipExportCsv" className="opacity-75">
              <span className="fs-7">{t('filters.noCsvData')}</span>
            </Tooltip>
          }
        >
          <div>
            <Button
              variant="link"
              className="d-flex align-items-center text-decoration-none"
              disabled={true}
            >
              <BsBoxArrowUpRight className="me-2" />
              {t('filters.export')}
            </Button>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};

export default ExportCsv;
