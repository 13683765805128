export default function formatNumber(number) {
  let numStr
  if (Number.isInteger(number)) {
    numStr = number.toString();
  } else {
    numStr = number.toFixed(2).toString();
  }

  const groups = numStr.match(/(\d{1,3})(?=(\d{3})+(?!\d))/g);

  return groups ? groups.join(' ') : numStr;
}