import { configureStore, combineReducers } from '@reduxjs/toolkit';
import filters from '../slices/filters';
import locationScans from '../slices/locationScans';
import preloader from '../slices/preloader';
import meQrData from '../slices/meQrData';
import mePosData from '../slices/mePosData';

const rootReducer = combineReducers({
  filters,
  locationScans,
  preloader,
  meQrData,
  mePosData
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
