import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import useRequestLocation from '../../../../hooks/useRequestLocation';
import { useTranslation } from 'react-i18next';

const EventSelector = ({selectedOptions, setSelectedOptions, allEventsOption}) => {
  const [availableOptions, setAvailableOptions] = useState([]);

  const {t} = useTranslation()

  const handleOptionToggle = (id, name) => {
    if (id === null) {
      setSelectedOptions([{ id: null, name: allEventsOption }]);
    } else {
      if (selectedOptions.map(el => el.id).includes(null)) {
        setSelectedOptions([{ id, name }]);
      } else if (selectedOptions.map(el => el.id).includes(id)) {
        if (selectedOptions.length === 1) {
          setSelectedOptions([{ id: null, name: allEventsOption }])
        } else {
          setSelectedOptions(selectedOptions.filter((item) => item.id !== id));
        }
      } else {
        setSelectedOptions([{id, name}, ...selectedOptions]);
      }
    }
  };
  const {getEventsFilters} = useRequestLocation()
  useEffect(() => {
    getEventsFilters().then(data => {
      const eventsArray = Object.entries(data.events).map(([id, name]) => ({ id: parseInt(id), name: name }))
      setAvailableOptions(eventsArray)
    })
  }, [])

  return (
    <Dropdown>
      <FloatingLabel
        label={t('meTicketFilters.nameEvent')}
        className="overflow-hidden gVRcaU rounded-1"
      >
        <Dropdown.Toggle variant="white" id="dropdown-basic"
                         className="col-12 rounded-1 gVRcaU form-select d-flex justify-content-between align-items-center h-100 form-floating border-0 bg-white">
          <div className="d-flex col-11">
            {selectedOptions.length === 1
              ? selectedOptions[0].name
              : selectedOptions.map((option) => (
                <div className="d-flex justify-content-between align-items-center border ticket-border-gray rounded-1 px-2 ms-1 text-truncate" key={option.id}>
                  <i onClick={(e) => {
                    e.stopPropagation()
                    handleOptionToggle(option.id, option.name)
                  }} className="pe-1 bi bi-x-lg cursor-pointer"></i>
                  {option.name}
                </div>
              ))}
          </div>

        </Dropdown.Toggle>
      </FloatingLabel>

      <Dropdown.Menu className="w-100">
        {availableOptions.map((option) => (
          <Dropdown.Item
            key={option.id}
            onClick={() => handleOptionToggle(option.id, option.name)}
            active={selectedOptions.map(el => el.id).includes(option.id)}
          >
            {option.name}
            {selectedOptions.includes(option.id) && (
              <span onClick={() => handleOptionToggle(option.id, option.name)}>&#10006;</span>
            )}
          </Dropdown.Item>
        ))}
        <Dropdown.Item
          onClick={() => handleOptionToggle(null, allEventsOption)}
          active={selectedOptions.map(el => el.id).includes(null)}
        >
          {allEventsOption}
          {selectedOptions.map(el => el.id).includes(null) && (
            <span onClick={() => handleOptionToggle(null, allEventsOption)}></span>
          )}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EventSelector;