import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({
                     preLable = '',
                     additionalClass = "",
                     formattedData = {}
                   }) => {
  const options = {
    responsive: true,
    aspectRatio: 1.25,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: formattedData.datasets.length > 0 && formattedData.datasets[0] !== "-1",
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(data) {
            return `${preLable ? preLable + ': ' : ''}` + data.raw;
          }
        }
      },
    },
  };


  return <Line options={options} data={formattedData} className={`my-4 ${additionalClass}`} />;
};

export default LineChart;
