import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: '',
  cities: []
};

const locationScans = createSlice({
  name: 'locationScans',
  initialState,
  reducers: {
    setCountry(state, { payload }) {
      state.country = payload;
      if (state.cities.length) {
        state.cities = [];
      }
    },
    setCity(state, { payload }) {
      state.cities.push(payload);
    },
    deleteCity(state, { payload }) {
      state.cities = state.cities.filter((item) => item !== payload);
    },
    deleteCountry(state) {
      state.country = '';
      state.cities = [];
    }
  }
});
export const { setCountry, setCity, deleteCity, deleteCountry } =
  locationScans.actions;
export default locationScans.reducer;
