import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  setCurrency,
  setIsStatisticData
} from '../../../redux/slices/mePosData';
import ExportCsvMePos from '../statistic/ExportCsvMePos';

const DataContainer = () => {
  const { t } = useTranslation();
  const {
    statisticType,
    startData,
    endData,
    service,
    master,
    haircutType,
    filters
  } = useSelector((state) => ({
    statisticType: state.filters.statisticType,
    startData: state.filters.periodStart,
    endData: state.filters.periodEnd,
    service: state.filters.service,
    master: state.filters.master,
    filters: state.filters,
    haircutType: state.filters.haircutType
  }));
  const dispatch = useDispatch();
  const [mainLabels, setMainLabels] = useState([]);
  const [dataList, setDataList] = useState([]);
  const periodToStart =
    startData !== null
      ? moment(startData).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD');
  const periodToEnd =
    endData !== null
      ? moment(endData).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  const [branch, setBranch] = useState({});
  const location = useLocation();

  const { getPaginationStat } = useRequestLocation();
  const params = new URLSearchParams(location.search);
  const urlParam = params.get('url');
  const { getFilial } = useRequestLocation();
  useEffect(() => {
    getFilial(periodToStart, periodToEnd).then((data) => {
      setBranch({ code: data.currency });
      dispatch(setCurrency(data.currency));
    });
  }, [urlParam]);

  useEffect(() => {
    handlePageChange(0);
    // const periodToStart = startData !== null
    //   ? moment(startData).format('YYYY-MM-DD')
    //   : moment().subtract(1, 'week').format('YYYY-MM-DD')
    // const periodToEnd = endData !== null
    //   ? moment(endData).format('YYYY-MM-DD')
    //   : moment().format('YYYY-MM-DD')
    getPaginationStat(
      periodToEnd,
      periodToStart,
      master,
      haircutType,
      service
    ).then((data) => {
      if (statisticType === 'DAILY_STATISTIC') {
        setMainLabels([
          t('statistic.date'),
          t('statistic.totalRecords'),
          t('statistic.bookingCompleted'),
          t('statistic.bookingCancel'),
          t('statistic.clientNotCome'),
          t('statistic.income'),
          t('statistic.maxPrice'),
          t('statistic.minPrice'),
          t('statistic.averagePrice')
        ]);
        setDataList(
          data.group_by_day.buckets.map((el) => {
            const completedData = el.group_by_status.buckets.find(
              (comp) => comp.key === 6
            );
            const completedDataPart = el.group_by_status.buckets.find(
              (comp) => comp.key === 7
            );
            const hasntArrived = el.group_by_status.buckets.find(
              (elem) => elem.key === 4
            );
            const canceledData = el.group_by_status.buckets.find(
              (elem) => elem.key === 5
            );
            const total =
              (completedData ? completedData.doc_count : 0) +
              (completedDataPart ? completedDataPart.doc_count : 0) +
              (hasntArrived ? hasntArrived.doc_count : 0) +
              (canceledData ? canceledData.doc_count : 0);
            const fullData = [el.key_as_string, total];
            const fullCompleted =
              completedData && completedDataPart
                ? completedData.doc_count + completedDataPart.doc_count
                : completedData
                ? completedData.doc_count
                : completedDataPart
                ? completedDataPart.doc_count
                : 0;
            const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
            const fullCanceled = canceledData ? canceledData.doc_count : 0;

            if (!completedData) {
              fullData.push([
                fullCompleted,
                `(${total ? Math.floor((fullCompleted / total) * 100) : 0}%)`
              ]);
              fullData.push([
                fullCanceled,
                `(${total ? Math.floor((fullCanceled / total) * 100) : 0}%)`
              ]);
              fullData.push([
                fullHasntArrived,
                `(${total ? Math.floor((fullHasntArrived / total) * 100) : 0}%)`
              ]);
              fullData.push(`0 ${branch.code}`);
              fullData.push(`0 ${branch.code}`);
              fullData.push(`0 ${branch.code}`);
              fullData.push(`0 ${branch.code}`);
              fullData.push('');
            } else {
              const numList = el.group_by_status.buckets
                .map((status) => {
                  if (status.key === 6 || status.key === 7) {
                    return status.price.buckets.map((price) => {
                      return [price.doc_count, price.key];
                    }, 0);
                  }
                  return 0;
                })
                .filter((el) => el !== 0)
                .flat(1);
              fullData.push([
                fullCompleted,
                `(${total ? Math.floor((fullCompleted / total) * 100) : 0}%)`
              ]);
              fullData.push([
                fullCanceled,
                `(${total ? Math.floor((fullCanceled / total) * 100) : 0}%)`
              ]);
              fullData.push([
                fullHasntArrived,
                `(${total ? Math.floor((fullHasntArrived / total) * 100) : 0}%)`
              ]);
              fullData.push(
                numList.reduce((acc, num) => acc + num[0] * num[1], 0) +
                  ' ' +
                  branch.code
              );
              fullData.push(
                Math.max(...numList.map((el) => el[1])) + ' ' + branch.code
              );
              fullData.push(
                Math.min(...numList.map((el) => el[1])) + ' ' + branch.code
              );
              fullData.push(
                (
                  numList.reduce((acc, el) => el[1] * el[0] + acc, 0) /
                  fullCompleted
                ).toFixed(2) +
                  ' ' +
                  branch.code
              );
              fullData.push('');
            }

            return fullData;
          })
        );
      }
      if (statisticType === 'POPULAR_SERVICE') {
        setMainLabels([
          t('statistic.serviceName'),
          t('statistic.subcategoryCategory'),
          t('statistic.totalRecords'),
          t('statistic.income'),
          t('statistic.maxPrice'),
          t('statistic.minPrice'),
          t('statistic.averagePrice')
        ]);
        setDataList(
          data.service.buckets.map((el) => {
            const numList = el.price.buckets.map((num) => {
              const count = num.status.buckets.reduce((acc, status) => {
                return status.key === 6 || status.key === 7
                  ? acc + status.doc_count
                  : acc;
              }, 0);
              return { key: num.key, count: count };
            });
            const filteredList = numList.filter((item) => item.count !== 0);
            const sum = filteredList.reduce((acc, item) => acc + item.key, 0);
            const maxKey = sum
              ? Math.max(...filteredList.map((item) => item.key))
              : 0;
            const minKey = sum
              ? Math.min(...filteredList.map((item) => item.key))
              : 0;
            const average = sum ? (sum / filteredList.length).toFixed(2) : 0;
            const list = [];
            list.push(el.key);
            list.push([
              `${el.subcategory.buckets[0].key}`,
              `(${el.category.buckets[0].key})`
            ]);
            list.push(el.doc_count);
            list.push(sum + ' ' + branch.code);
            list.push(maxKey + ' ' + branch.code);
            list.push(minKey + ' ' + branch.code);
            list.push(average + ' ' + branch.code);
            list.push('');
            return list;
          })
        );
      }
      if (statisticType === 'POPULAR_MASTER') {
        setMainLabels([
          t('statistic.master'),
          t('statistic.totalRecords'),
          t('statistic.income'),
          t('statistic.maxPrice'),
          t('statistic.minPrice'),
          t('statistic.averagePrice')
        ]);
        setDataList(
          data.masters.buckets.map((el) => {
            const numList = el.price.buckets.map((num) => {
              const count = num.status.buckets.reduce((acc, status) => {
                return status.key === 6 || status.key === 7
                  ? acc + status.doc_count
                  : acc;
              }, 0);
              return { key: num.key, count: count };
            });
            const filteredList = numList.filter((item) => item.count !== 0);
            const sum = filteredList.reduce((acc, item) => acc + item.key, 0);
            const maxKey = sum
              ? Math.max(...filteredList.map((item) => item.key))
              : 0;
            const minKey = sum
              ? Math.min(...filteredList.map((item) => item.key))
              : 0;
            const average = sum ? (sum / filteredList.length).toFixed(2) : 0;
            const list = [];
            list.push(
              `${el.name.buckets[0].key} ${el.surname.buckets[0].key} (${el.position.buckets[0].key})`
            );
            list.push(el.doc_count);
            list.push(sum + ' ' + branch.code);
            list.push(maxKey + ' ' + branch.code);
            list.push(minKey + ' ' + branch.code);
            list.push(average + ' ' + branch.code);
            list.push('');
            return list;
          })
        );
      }
      if (statisticType === 'COMPLETE_MASTER') {
        setMainLabels([
          t('statistic.master'),
          t('statistic.totalRecords'),
          t('statistic.bookingCompleted'),
          t('statistic.bookingCancel'),
          t('statistic.clientNotCome')
        ]);
        setDataList(
          data.masters.buckets.map((el) => {
            const list = [];
            list.push(
              `${el.name.buckets[0].key} ${el.surname.buckets[0].key} (${el.position.buckets[0].key})`
            );
            const completedData = el.status.buckets.find(
              (comp) => comp.key === 6
            );
            const completedDataPart = el.status.buckets.find(
              (comp) => comp.key === 7
            );
            const hasntArrived = el.status.buckets.find(
              (elem) => elem.key === 4
            );
            const canceledData = el.status.buckets.find(
              (elem) => elem.key === 5
            );
            const totalSum =
              (completedData ? completedData.doc_count : 0) +
              (hasntArrived ? hasntArrived.doc_count : 0) +
              (canceledData ? canceledData.doc_count : 0) +
              (completedDataPart ? completedDataPart.doc_count : 0);
            list.push(totalSum);
            const fullCompleted =
              completedData && completedDataPart
                ? completedData.doc_count + completedDataPart.doc_count
                : completedData
                ? completedData.doc_count
                : completedDataPart
                ? completedDataPart.doc_count
                : 0;
            const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
            const fullCanceled = canceledData ? canceledData.doc_count : 0;
            list.push([
              fullCompleted,
              `(${
                totalSum ? Math.floor((fullCompleted / totalSum) * 100) : 0
              }%)`
            ]);
            list.push([
              fullCanceled,
              `(${totalSum ? Math.floor((fullCanceled / totalSum) * 100) : 0}%)`
            ]);
            list.push([
              fullHasntArrived,
              `(${
                totalSum ? Math.floor((fullHasntArrived / totalSum) * 100) : 0
              }%)`
            ]);
            list.push('');
            return list;
          })
        );
      }
      if (statisticType === 'COMPLETE_SERVICE') {
        setMainLabels([
          t('statistic.serviceName'),
          t('statistic.subcategoryCategory'),
          t('statistic.totalRecords'),
          t('statistic.bookingCompleted'),
          t('statistic.bookingCancel'),
          t('statistic.clientNotCome')
        ]);
        setDataList(
          data.service.buckets.map((el) => {
            const list = [];
            const completedData = el.status.buckets.find(
              (comp) => comp.key === 6
            );
            const completedDataPart = el.status.buckets.find(
              (comp) => comp.key === 7
            );
            const hasntArrived = el.status.buckets.find(
              (elem) => elem.key === 4
            );
            const canceledData = el.status.buckets.find(
              (elem) => elem.key === 5
            );
            list.push(el.key);
            list.push([
              `${el.subcategory.buckets[0].key}`,
              `(${el.category.buckets[0].key})`
            ]);
            const totalSum =
              (completedData ? completedData.doc_count : 0) +
              (hasntArrived ? hasntArrived.doc_count : 0) +
              (canceledData ? canceledData.doc_count : 0) +
              (completedDataPart ? completedDataPart.doc_count : 0);
            list.push(totalSum);
            const fullCompleted =
              completedData && completedDataPart
                ? completedData.doc_count + completedDataPart.doc_count
                : completedData
                ? completedData.doc_count
                : completedDataPart
                ? completedDataPart.doc_count
                : 0;
            const fullHasntArrived = hasntArrived ? hasntArrived.doc_count : 0;
            const fullCanceled = canceledData ? canceledData.doc_count : 0;
            list.push([
              fullCompleted,
              `(${
                totalSum ? Math.floor((fullCompleted / totalSum) * 100) : 0
              }%)`
            ]);
            list.push([
              fullCanceled,
              `(${totalSum ? Math.floor((fullCanceled / totalSum) * 100) : 0}%)`
            ]);
            list.push([
              fullHasntArrived,
              `(${
                totalSum ? Math.floor((fullHasntArrived / totalSum) * 100) : 0
              }%)`
            ]);
            list.push('');
            return list;
          })
        );
      }
    });
  }, [filters, branch]);

  useEffect(() => {
    dispatch(setIsStatisticData(dataList.length > 0));
  }, [dataList]);

  const rowsPerPage = 5; // Количество рядов на странице

  const [currentPage, setCurrentPage] = useState(0); // Текущая страница пагинации

  // Вычисление индекса начала и конца рядов для текущей страницы
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Получение данных для текущей страницы
  const paginatedDataList = dataList.slice(startIndex, endIndex);

  // Обработчик изменения страницы пагинации
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(dataList.length / rowsPerPage);

  const renderPaginationItems = () => {
    const paginationItems = [];

    // Помощник для добавления ссылки на страницу
    const addPageLink = (page, label = null, isSelectable = true) => {
      paginationItems.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={
            'px-2 m-2 btn pos-primary-btn pagination-link' +
            (page === currentPage ? ' active' : '')
          }
          disabled={!isSelectable}
        >
          {label ? label : page + 1}
        </button>
      );
    };

    if (totalPages <= 7) {
      for (let i = 0; i < totalPages; i++) {
        addPageLink(i);
      }
    } else if (currentPage <= 2) {
      for (let i = 0; i < 4; i++) {
        addPageLink(i, i + 1, true);
      }
      addPageLink(4, '...', false);
      addPageLink(totalPages - 1, totalPages, true);
    } else if (currentPage >= totalPages - 3) {
      addPageLink(0, 1, true);
      addPageLink(1, '...', false);
      for (let i = totalPages - 4; i < totalPages; i++) {
        addPageLink(i, i + 1, true);
      }
    } else {
      addPageLink(0, 1, true);
      addPageLink(1, '...', false);
      addPageLink(currentPage - 1, currentPage, true);
      addPageLink(currentPage, currentPage + 1, true);
      addPageLink(currentPage + 1, currentPage + 2, true);
      addPageLink(totalPages - 2, '...', false);
      addPageLink(totalPages - 1, totalPages, true);
    }

    paginationItems.unshift(
      <button
        className="bg-white"
        key="prev"
        disabled={currentPage === 0}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <i className="bi bi-chevron-double-left"></i>
      </button>
    );

    // Добавляем ссылку на "Следующую страницу"
    paginationItems.push(
      <button
        className="bg-white"
        key="next"
        disabled={currentPage === totalPages - 1}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <i className="bi bi-chevron-double-right"></i>
      </button>
    );

    return paginationItems;
  };
  return (
    <div className="overflow-auto scrollbar">
      <div
        className="p-3 d-flex flex-column col-12 "
        style={{ width: '99%', minWidth: '880px' }}
      >
        {paginatedDataList.length ? (
          <>
            <div
              className="d-flex justify-content-between align-items-center p-2"
              style={{ backgroundColor: '#F9F8FD' }}
            >
              {mainLabels.map((elem, idx) => (
                <div
                  className="col-1 pe-2"
                  style={{
                    flex:
                      statisticType === 'POPULAR_SERVICE'
                        ? idx === 0 || idx === 1
                          ? '0 0 18%'
                          : '0 0 auto'
                        : statisticType === 'POPULAR_MASTER'
                        ? idx === 0
                          ? '0 0 20%'
                          : '0 0 13.3%'
                        : statisticType === 'COMPLETE_MASTER'
                        ? idx === 0
                          ? '0 0 25%'
                          : '0 0 auto'
                        : statisticType === 'COMPLETE_SERVICE'
                        ? idx === 0 || idx === 1
                          ? '0 0 20%'
                          : '0 0 12%'
                        : '0 0 auto'
                  }}
                >
                  {elem}
                </div>
              ))}
              <div
                className="d-flex justify-content-end"
                style={{
                  flex:
                    statisticType === 'POPULAR_SERVICE'
                      ? '0 0 auto'
                      : statisticType === 'POPULAR_MASTER'
                      ? '0 0 13.3%'
                      : statisticType === 'COMPLETE_MASTER'
                      ? '0 0 auto'
                      : statisticType === 'COMPLETE_SERVICE'
                      ? '0 0 12%'
                      : '0 0 auto',
                  backgroundColor: '#F9F8FD'
                }}
              >
                <ExportCsvMePos />
              </div>
            </div>
            <div className="d-flex flex-column">
              {paginatedDataList.map((el) => (
                <div className="d-flex col-12 justify-content-between border-bottom p-3">
                  {el.map((elem, idx) => (
                    <div
                      className="pe-2 col-1"
                      style={{
                        flex:
                          statisticType === 'POPULAR_SERVICE'
                            ? idx === 0 || idx === 1
                              ? '0 0 18%'
                              : idx === el.length - 1
                              ? '0 0 90px'
                              : '0 0 auto'
                            : statisticType === 'POPULAR_MASTER'
                            ? idx === 0
                              ? '0 0 20%'
                              : '0 0 13.3%'
                            : statisticType === 'COMPLETE_MASTER'
                            ? idx === 0
                              ? '0 0 25%'
                              : '0 0 auto'
                            : statisticType === 'COMPLETE_SERVICE'
                            ? idx === 0 || idx === 1
                              ? '0 0 20%'
                              : '0 0 12%'
                            : idx === el.length - 1
                            ? '0 0 90px'
                            : '0 0 auto'
                      }}
                    >
                      {Array.isArray(elem) ? (
                        <>
                          <div className="font-16">{elem[0]}</div>
                          <div className="opacity-50">{elem[1]}</div>
                        </>
                      ) : (
                        <div className="font-16">{elem}</div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <div className="col-9 offset-md-2 d-flex justify-content-center">
                <div className="pagination">{renderPaginationItems()}</div>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <div className="opacity-50">
                  {(currentPage + 1) * 5 - 4} -{' '}
                  {currentPage !== totalPages - 1
                    ? (currentPage + 1) * 5
                    : dataList.length}{' '}
                  {t('statistic.from')} {dataList.length}
                </div>
              </div>
            </div>
          </>
        ) : (
          <h3 className="col-lg-12 col-5 mb-5 pb-5 text-lg-center text-start">
            {t('statistic.noData')}
          </h3>
        )}
      </div>
    </div>
  );
};

export default DataContainer