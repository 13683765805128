import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RecordsChartHorizontalBar = ({ label, labels, dataCounts, color, borderColor, addtitionalData={}, additionalDatasets=[] }) => {
  const {t} = useTranslation()
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return (
              `${
                data.datasetIndex === 0
                  ? label
                  : data.datasetIndex === 1 ?
                    `${t('statistic.recordCancel')}: `: `${t('statistic.clientNotCome')}: `
              }` + data.raw
            );
          }
        }
      },

    }
  };

  const data = {
    labels,
    datasets: [
      {
        data: dataCounts,
        backgroundColor: color,
        borderColor: borderColor,
        borderWidth: 1,
        ...addtitionalData
      },
      ...additionalDatasets
    ]
  };
  return (
    <div style={{ height: 250 }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default RecordsChartHorizontalBar;
