import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



const Title = () => {
  const { t } = useTranslation()
  return (
    <Col xs={10} >
      <h2 className='fw-normal mb-0 gap-1 text-dark'>
         {t('booking.title')}
      </h2>
      <div className="d-flex gap-2 text-info">
        <div className=''>{t('booking.title2')}</div>
        <div className=''><span>></span></div>
        <div className=''>{t('booking.title')}</div>
      </div>
    </Col>
  );
};

export default Title;
