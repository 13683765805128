import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isExportCsvActive: false,
  isStatisticCollecting: false,
  statisticPath: '',
  pagination: {
    page: 1,
    maxPage: 1
  },
  folderId: null
};

const meQrData = createSlice({
  name: 'meQrData',
  initialState,
  reducers: {
    setIsExportCsvActive(state, { payload }) {
      state.isExportCsvActive = payload;
    },
    setPaginationPage(state, { payload }) {
      state.pagination.page = payload;
    },
    setPaginationMaxPage(state, { payload }) {
      state.pagination.maxPage = payload;
    },
    setIsStatisticCollecting(state, { payload }) {
      state.isStatisticCollecting = payload;
    },
    setStatisticPath(state, { payload }) {
      state.statisticPath = payload;
    },
    setFolderId(state, { payload }) {
      state.folderId = payload;
    }
  }
});
export const {
  setIsExportCsvActive,
  setPaginationPage,
  setPaginationMaxPage,
  setIsStatisticCollecting,
  setStatisticPath,
  setFolderId
} = meQrData.actions;
export default meQrData.reducer;
