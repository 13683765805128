import Dropdown from 'react-bootstrap/Dropdown';
import { DropField } from '../../../elements/general';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../../hooks/useRequestLocation';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const HaircutSelectFilter = ({ endData, startData, selectedMaster, setHairstyles, setService, selectedHairstyle}) => {
  const [hairstylesList, setHairstylesList] = useState([])
  const { t } = useTranslation();
  const { getHairstyles } = useRequestLocation();

  useEffect(() => {
    const periodToStart = startData !== null
      ? moment(startData).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = endData !== null
      ? moment(endData).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getHairstyles(periodToEnd, periodToStart, selectedMaster).then((countHairstyles) => {
      setHairstylesList(countHairstyles.map(el => {
        return el.subcategory.buckets.map(sub => {
          return { key: sub.key, category: el.key }
        })
      }).flat());
    });
  }, [startData, endData, selectedMaster])

  const handleSelect = (key) => {
    setHairstyles(key);
  };

  return (
    <DropField className='custom-dropdown py-2 rounder-4px border'>
      <Dropdown.Toggle className="form-select">
        {hairstylesList.find((item) => item.key === selectedHairstyle) ?
          `${hairstylesList.find((item) => item.key === selectedHairstyle).key}
          (${hairstylesList.find((item) => item.key === selectedHairstyle).category})`
          :
          t('filters.allCategories')
        }
      </Dropdown.Toggle>

      <Dropdown.Menu className='custom-dropdown-menu'>
        <Dropdown.Item onClick={() => {
          handleSelect('all')
          setService('all')
        }} className={selectedHairstyle === 'all' ? 'active' : ''}
        >
          {t('filters.allCategories')}
        </Dropdown.Item>
        {hairstylesList.map((item) => (
          <Dropdown.Item key={item.key} onClick={() => handleSelect(item.key)}
                         className={item === selectedHairstyle ? 'active' : ''}>
                     {item.key} ({item.category})
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>

    </DropField>
  );
};

export default HaircutSelectFilter;
