import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useRequestLocation from '../../../hooks/useRequestLocation';
import DefaultScans from '../totalScans/DefaultScans';
import PeriodScans from '../totalScans/PeriodScans';
import CompareScans from '../totalScans/CompareScans';
import UniqIcon from './svg/UniqIcon';

const UniqueUsers = () => {
  const [uniq, setUniq] = useState(0);
  const { getTotalScans, getFilteredTotalScans } = useRequestLocation();
  const [firstPeriod, setFirstPeriod] = useState(0);
  const [secondPeriod, setSecondPeriod] = useState(0);

  const {
    mode,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    filters,
    page
  } = useSelector((state) => ({
    mode: state.filters.mode,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    filters: state.filters,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    getTotalScans(true).then((data) => mounted && setUniq(data));
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [filters.qrIds, page]);

  useEffect(() => {
    if (mode === 'period') {
      getFilteredTotalScans(periodStart, periodEnd, true).then((data) =>
        setFirstPeriod(data.filteredScans)
      );
    }
    if (mode === 'compare') {
      getFilteredTotalScans(secondPeriodStart, secondPeriodEnd, true).then(
        (data) => setSecondPeriod(data.filteredScans)
      );
      getFilteredTotalScans(periodStart, periodEnd, true).then((data) =>
        setFirstPeriod(data.filteredScans)
      );
    }
    // eslint-disable-next-line
  }, [mode, filters, page]);

  return (
    <Col xs={12} lg={6}>
      <div className="p-3 p-lg-4 bg-white h-100">
        <Row>
          <Col xs={9}>
            <div className="fw-bold fs-4 mb-2 text-capitalize">
              {t('scans.unique')}
            </div>
            <div className="text-gray fs-7 mb-3 mb-lg-4">
              {t('scans.descriptionUniq')}
            </div>
          </Col>
          <Col xs={3} className="d-flex justify-content-end align-items-start">
            <UniqIcon />
          </Col>
        </Row>
        {mode === 'default' && <DefaultScans scans={uniq} />}
        {mode === 'period' && <PeriodScans scans={firstPeriod} total={uniq} />}
        {mode === 'compare' && (
          <CompareScans firstPeriod={firstPeriod} secondPeriod={secondPeriod} />
        )}
      </div>
    </Col>
  );
};

export default UniqueUsers;
