import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currency: '',
  isStatisticData: false
};

const mePosData = createSlice({
  name: 'mePosData',
  initialState,
  reducers: {
    setCurrency(state, { payload }) {
      state.currency = payload;
    },
    setIsStatisticData(state, { payload }) {
      state.isStatisticData = payload;
    }
  }
});
export const { setCurrency, setIsStatisticData } = mePosData.actions;
export default mePosData.reducer;
