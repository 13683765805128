export const colors = [
  {bright: '#7BD594', faded: "#E5FAED", fullBright: "#57AB6E"},
  {bright: "#6F93EF", faded: "#E2E9FC", fullBright: "#5979CC"},
  {bright: "#F4C1BD", faded: "#FEEEED", fullBright: "#D8A29E"},
  {bright: "#EFCB6F", faded: "#FDF2D7", fullBright: "#d38e49"},
  {bright: "#c46fef", faded: "#ebd7fd", fullBright: "#9459cc"},
  {bright: "#6fefd1", faded: "#d7fdee", fullBright: "#57ab92"},
  {bright: "#ef6fd5", faded: "#fdd7fc", fullBright: "#d349d1"},
]

export const solidBlueColor = "#1547AA"