import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ColorLabel } from '../../elements/general';
import { DAY } from '../../../constants';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import RecordsChartHorizontalBar from '../../charts/recordsChartHorBar';
import { useTranslation } from 'react-i18next';

const RecordMasterStat = () => {
  const {
    isFitersActive,
    periodStart,
    periodEnd,
    filters,
    master,
    haircutType,
    service
  } = useSelector((state) => ({
    isFitersActive: state.filters.isFitersActive,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    filters: state.filters,
    master: state.filters.master,
    haircutType: state.filters.haircutType,
    service: state.filters.service
  }));

  const [labels, setLabels] = useState([]);
  const [labels2, setLabels2] = useState([]);
  const [dataCounts, setDataCounts] = useState([]);
  const [dataCounts2, setDataCounts2] = useState([]);

  const [widthSize, setWidthSize] = useState('full')
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      if (innerWidth >= 1440) {
        setWidthSize('full');
      } else if (innerWidth >= 1200) {
        setWidthSize('medium');
      } else if (innerWidth >= 1024) {
        setWidthSize('small');
      } else {
        setWidthSize('tiny');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [innerWidth]);

  function truncateTextWithEllipsis(text) {
    let maxCharacters = 32
    if (widthSize === 'full') {
      maxCharacters = 32
    } else if (widthSize === 'medium') {
      maxCharacters = 25
    } else if (widthSize === 'small') {
      maxCharacters = 17
    } else if (widthSize === 'tiny') {
      maxCharacters = 9
    }
    if (text.length <= maxCharacters) {
      return text
    }

    const truncatedText = text.slice(0, maxCharacters); // Обрезаем строку до нужного количества символов (минус 3 для троеточия).
    return truncatedText + '...';
  }

  const { getServicePop } = useRequestLocation();

  const { t } = useTranslation();
  useEffect(() => {
    const periodToStart = periodStart !== null
      ? moment(periodStart).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').format('YYYY-MM-DD')
    const periodToEnd = periodEnd !== null
      ? moment(periodEnd).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
    getServicePop(periodToEnd, periodToStart, master, haircutType, service, false).then((services) => {
      setDataCounts(services.count_masters.buckets.map(item => item.doc_count));
      setLabels(
        services.count_masters.buckets.map(
          (item) =>
            truncateTextWithEllipsis(`${item.name.buckets[0].key} ${item.surname.buckets[0].key} (${item.position.buckets[0].key})`)
        )
      );
    });
    getServicePop(periodToEnd, periodToStart, master, haircutType, service, true).then((masters) => {
      // setDataCounts2(services.count_masters.buckets.map(item => item.price.buckets.reduce((acc, el) => acc + el.key*el.doc_count, 0)));
      setDataCounts2(masters.count_masters.buckets.map(item =>
        item.price.buckets.reduce((acc, el) => {
          const price = el.status.buckets.reduce((accum, status) => {
            return status.key === 6 || status.key === 7 ?
              accum + el.key * el.doc_count: accum}, 0
          )
          return acc + price
        }, 0)
      ));

      setLabels2(
        masters.count_masters.buckets.map(
          (item) =>
            truncateTextWithEllipsis(`${item.name.buckets[0].key} ${item.surname.buckets[0].key} (${item.position.buckets[0].key})`)
        )
      );
    })
  }, [filters]);


  return (
    <Row>
      <Col xs={6} lg={6}>
        <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
          <Col xs={12} lg="auto">
            <div className="h4-heads mb-2 text-capitalize">
              {t('statistic.recordPopularity')}
            </div>
          </Col>
          <Row className="justify-content-center">
            <RecordsChartHorizontalBar
              label={`${t('statistic.records')}: `}
              labels={labels}
              dataCounts={dataCounts}
              color="#EEECF9"
              borderColor="#5842BE"
            />
          </Row>
        </div>
      </Col>
      <Col xs={6} lg={6}>
        <div className="p-3 p-lg-4 bg-white h-100 d-flex flex-column justify-content-between">
          <Col xs={12} lg="auto">
            <div className="h4-heads mb-2 text-capitalize">
              {t('statistic.incomePopularity')}
            </div>
          </Col>
          <Row className="justify-content-center">
            <RecordsChartHorizontalBar
              label={`${t('statistic.income')}: `}
              labels={labels2}
              dataCounts={dataCounts2}
              color="#E8F7F4"
              borderColor="#1AB394"
            />
          </Row>
        </div>
      </Col>
    </Row>

  );
};

export default RecordMasterStat;
