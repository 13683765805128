import { useTranslation } from 'react-i18next';
import Stat from './stat';
import { Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useRequestLocation from '../../../hooks/useRequestLocation';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
function ReturnsStat() {
  const {t} = useTranslation()

  const [value, setValue] = useState(undefined)
  const [dataByPeriod, setDataByPeriod] = useState(undefined)
  const [percent, setPercent] = useState(null)

  const filters = useSelector(state => state.filters)

  const {getEventsFilters} = useRequestLocation()
  useEffect(() => {
    getEventsFilters(filters.periodStart, filters.periodEnd, filters.eventsArray).then(data => {
      if (data.ticketReturnsAmount !== data.ticketReturnsAmountByPeriod && filters.periodStart && filters.periodEnd) {
        const momentStartDate = moment.isMoment(filters.periodStart) ? filters.periodStart : moment(filters.periodStart);
        const momentEndDate = moment.isMoment(filters.periodEnd) ? filters.periodEnd : moment(filters.periodEnd);
        const differ = momentEndDate.diff(momentStartDate, 'days') + 1
        getEventsFilters(
          momentStartDate.clone().subtract(differ, 'days'),
          momentEndDate.clone().subtract(differ, 'days'),
          filters.eventsArray)
          .then(previousData => {
            const previousValue = previousData.ticketReturnsAmountByPeriod
            const percentage = (data.ticketReturnsAmountByPeriod - previousValue) / previousValue * 100
            setPercent(parseInt(percentage))
          })
      }
      setValue(data.ticketReturnsAmount)
      setDataByPeriod(data.summaryViewsByPeriod)
    })
  }, [filters]);
  return (
    <Col className={"col-md-6"}>
      <Stat icon={'arrow-counterclockwise'}
            mainLabel={t('ticketStatistic.ticketReturns')}
            smallLabel={t('ticketStatistic.ticketReturnsDescription')}
            value={value}
            percent={percent}
            dataByPeriod={dataByPeriod}
            col={"Returns"}
      />
    </Col>
  )
}

export default ReturnsStat