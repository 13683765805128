import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { setFilters } from '../../../../redux/slices/filters';
import DateFilter from './DateFilter';
import { useEffect, useState } from 'react';
import EventSelector from './EventSelector';
import { useDispatch } from 'react-redux';


const START_DATE_RANGE = {
  selection1: {
    startDate: null,
    endDate: null,
    key: 'selection1'
  }
};

const Filters = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const allEventsOption = t('ticketStatistic.allEvents');
  const [selectedOptions, setSelectedOptions] = useState([{ id: null, name: allEventsOption }]);

  useEffect(() => {
    dispatch(setFilters({
      periodStart: null,
      periodEnd: null,
      eventsArray: []
    }))
  }, [])

  const applyFilters = () => {
    const filterOptions = {
      periodStart: dateRange.selection1.startDate
        ? moment(dateRange.selection1.startDate)
        : null,
      periodEnd: dateRange.selection1.endDate
        ? moment(dateRange.selection1.endDate).endOf('D').toDate()
        : null,
      eventsArray: selectedOptions.map(option => option.id).filter(id => id !== null)
    }
    dispatch(setFilters(filterOptions))
  }

  const [dateRange, setDateRange] = useState(START_DATE_RANGE)

  return (
    <>
      <section>
        <div className="bg-secondary p-3 p-lg-4 rounded-1">

            <Col>
              <div className="d-flex justify-content-between alignt-itens-center mb-3">
                <h4 className="position-relative me-3">
                  {t('meTicketFilters.filters')}
                </h4>
              </div>
              <Row>
                <Col xs={12} lg={5} className="col-lg-5 col-12 mb-xl-0 mb-3">
                <EventSelector selectedOptions={selectedOptions}
                               setSelectedOptions={setSelectedOptions}
                               allEventsOption={allEventsOption}/>
              </Col>
                <Col xs={12} lg={5} className="mb-xl-0 mb-2">
                  <DateFilter
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Col>
                <Col lg={2} xs={12}>
                  <Button className="w-100 fw-bold py-3" onClick={applyFilters}>
                    {t('filters.applyFilters')}
                  </Button>
                </Col>
              </Row>
            </Col>

        </div>
      </section>
    </>
  );
};

export default Filters;