const CalendarIcon = (props) => (
  <svg
    width={14}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#8B929A"
      d="M3.063.063c.241 0 .437.195.437.437v.438h7V.5a.438.438 0 0 1 .875 0v.438h.875c.966 0 1.75.783 1.75 1.75v9.624a1.75 1.75 0 0 1-1.75 1.75H1.75A1.75 1.75 0 0 1 0 12.313V2.688C0 1.72.784.938 1.75.938h.875V.5c0-.242.196-.438.438-.438ZM1.75 1.813a.875.875 0 0 0-.875.875v.874h12.25v-.874a.875.875 0 0 0-.875-.876H1.75Zm11.375 2.625H.875v7.875c0 .483.392.874.875.874h10.5a.875.875 0 0 0 .875-.874V4.436Z"
    />
    <path
      fill="#8B929A"
      d="M7.875 6.625c0-.242.196-.438.438-.438h4.812v1.75H8.312a.438.438 0 0 1-.437-.437v-.875ZM6.125 9.25v.875a.438.438 0 0 1-.438.438H.875v-1.75h4.813c.241 0 .437.195.437.437Z"
    />
  </svg>
);

export default CalendarIcon;
