import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import useRequestLocation from '../../../hooks/useRequestLocation';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EmptyTable from './EmptyTable';
import { useTranslation } from 'react-i18next';


const emptyData = Array.from({ length: 8 }).map((_, index) => ({
  id: index + 1,
  country: '',
  city: '',
  scans: '',
  users: ''
}));

const CountryTable = ({ rows = 10, modeCompare = false }) => {
  const {
    filters,
    country,
    cities,
    periodStart,
    periodEnd,
    secondPeriodStart,
    secondPeriodEnd,
    page
  } = useSelector((state) => ({
    filters: state.filters,
    country: state.locationScans.country,
    cities: state.locationScans.cities,
    periodStart: state.filters.periodStart,
    periodEnd: state.filters.periodEnd,
    secondPeriodStart: state.filters.secondPeriodStart,
    secondPeriodEnd: state.filters.secondPeriodEnd,
    page: state.meQrData.pagination.page
  }));
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const { getTableData } = useRequestLocation();

  const fetchTableData = async ({ startData, endData }) => {
    try {
      const data = await getTableData({ startData, endData });
      let configuredData = [];
      if (country || cities.length) {
        configuredData = data.dataWithCity[0].top_city.buckets.map(
          (item, index) => {
            return {
              country: country,
              city: item.key,
              scans: item.doc_count,
              users: item.unique_doc_count.doc_count,
              id: index
            };
          }
        );
        if (cities.length) {
          configuredData.sort((a, b) => {
            const aIndex = cities.indexOf(a.city);
            const bIndex = cities.indexOf(b.city);
            if (aIndex === -1) {
              return 1; // move items not in sampleArray to the end of the array
            } else if (bIndex === -1) {
              return -1; // move items not in sampleArray to the end of the array
            } else {
              return aIndex - bIndex; // sort based on index in sampleArray
            }
          });
        }
      } else {
        let id = 0;
        configuredData = data.dataWithCity.flatMap((item) => {
          return item.top_city.buckets.flatMap((cityItem) => {
            id++;
            return {
              country: item.key,
              city: cityItem.key,
              scans: cityItem.doc_count,
              users: cityItem.unique_doc_count.doc_count,
              id
            };
          });
        });
      }
      setTableData(configuredData);
    } catch (err) {}
  };

  useEffect(() => {
    if (modeCompare) {
      fetchTableData({
        startData: secondPeriodStart,
        endData: secondPeriodEnd
      });
    } else {
      fetchTableData({ startData: periodStart, endData: periodEnd });
    }
    // eslint-disable-next-line
  }, [country, cities, filters, modeCompare, page]);

  const columns = [
    {
      dataField: 'country',
      text: t('location.country'),
      sort: true
    },
    {
      dataField: 'city',
      text: t('location.city'),
      sort: true
    },
    {
      dataField: 'scans',
      text: t('location.scans'),
      sort: true
    },
    {
      dataField: 'users',
      text: t('location.users'),
      sort: true
    }
  ];
  const CustomNextArrow = ({ onClick }) => (
    <svg
      className="custom-pagination-arrow"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >

      <path d="M5.81057 2.05862C5.86863 2.00041 5.9376 1.95423 6.01353 1.92273C6.08946 1.89122 6.17086 1.875 6.25307 1.875C6.33528 1.875 6.41668 1.89122 6.49261 1.92273C6.56854 1.95423 6.63751 2.00041 6.69557 2.05862L14.1956 9.55862C14.2538 9.61667 14.3 9.68564 14.3315 9.76158C14.363 9.83751 14.3792 9.91891 14.3792 10.0011C14.3792 10.0833 14.363 10.1647 14.3315 10.2407C14.3 10.3166 14.2538 10.3856 14.1956 10.4436L6.69557 17.9436C6.57821 18.061 6.41904 18.1269 6.25307 18.1269C6.0871 18.1269 5.92793 18.061 5.81057 17.9436C5.69321 17.8263 5.62728 17.6671 5.62728 17.5011C5.62728 17.3351 5.69321 17.176 5.81057 17.0586L12.8693 10.0011L5.81057 2.94362C5.75237 2.88556 5.70619 2.81659 5.67468 2.74066C5.64317 2.66473 5.62695 2.58333 5.62695 2.50112C5.62695 2.41891 5.64317 2.33751 5.67468 2.26158C5.70619 2.18564 5.75237 2.11667 5.81057 2.05862Z"

      />
    </svg>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <svg
      className="custom-pagination-arrow"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.1956 2.05862C14.2538 2.11667 14.3 2.18564 14.3315 2.26158C14.363 2.33751 14.3792 2.41891 14.3792 2.50112C14.3792 2.58333 14.363 2.66473 14.3315 2.74066C14.3 2.81659 14.2538 2.88556 14.1956 2.94362L7.13682 10.0011L14.1956 17.0586C14.3129 17.176 14.3789 17.3351 14.3789 17.5011C14.3789 17.6671 14.3129 17.8263 14.1956 17.9436C14.0782 18.061 13.919 18.1269 13.7531 18.1269C13.5871 18.1269 13.4279 18.061 13.3106 17.9436L5.81057 10.4436C5.75237 10.3856 5.70619 10.3166 5.67468 10.2407C5.64317 10.1647 5.62695 10.0833 5.62695 10.0011C5.62695 9.91891 5.64317 9.83751 5.67468 9.76158C5.70619 9.68564 5.75237 9.61667 5.81057 9.55862L13.3106 2.05862C13.3686 2.00041 13.4376 1.95423 13.5135 1.92273C13.5895 1.89122 13.6709 1.875 13.7531 1.875C13.8353 1.875 13.9167 1.89122 13.9926 1.92273C14.0685 1.95423 14.1375 2.00041 14.1956 2.05862Z"
      />
    </svg>
  );
  const paginationOptions = {
    sizePerPage: rows,
    paginationSize: 4,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    nextPageText: <CustomNextArrow />,
    prePageText: <CustomPrevArrow />,

  };

  return (
    <TableWrapper>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={tableData}
        columns={columns}
        bordered={false}
        hover
        wrapperClasses="table-responsive"
        noDataIndication={() => <EmptyTable emptyData={emptyData} />}
        pagination={paginationFactory(paginationOptions)}
      />
    </TableWrapper>
  );
};

export default CountryTable;

const TableWrapper = styled.div`
  .table-container {
    min-width: 400px;
  }

  .react-bootstrap-table table {
    min-width: 400px;
  }
`;
