import { useSelector } from 'react-redux';
import CalendarIcon from './svg/CalendarIcon';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { calculateDiff, getPeriodString } from '../../../hooks/helpers';
import ArrowUp from './svg/ArrowUp';
import ArrowDown from './svg/ArrowDown';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const CompareScans = ({ firstPeriod, secondPeriod }) => {
  const { periodStart, periodEnd, secondPeriodStart, secondPeriodEnd } =
    useSelector((state) => ({
      periodStart: state.filters.periodStart,
      periodEnd: state.filters.periodEnd,
      secondPeriodStart: state.filters.secondPeriodStart,
      secondPeriodEnd: state.filters.secondPeriodEnd
    }));

  const { t } = useTranslation();

  const diff = calculateDiff(firstPeriod, secondPeriod);

  return (
    <Row className="gy-3">
      <Col>
        <div className="border-end">
          <div className="text-gray fs-7 d-flex align-items-center">
            <span className="me-2 pb-1">
              <CalendarIcon />
            </span>
            {getPeriodString(secondPeriodStart, secondPeriodEnd)}
          </div>
          <div className="fw-bold fs-2">{secondPeriod}</div>
        </div>
      </Col>
      <Col>
        <div className="text-gray fs-7 d-flex align-items-center">
          <span className="me-2 pb-1">
            <CalendarIcon />
          </span>
          {getPeriodString(periodStart, periodEnd)}
        </div>
        <div className="d-flex align-items-end">
          <div className="fw-bold fs-2 me-2">{firstPeriod}</div>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltipPercentage" className="opacity-75">
                <span className="fs-7">{t('scans.percentage')}</span>
              </Tooltip>
            }
          >
            <div
              className={classNames(
                'bg-opacity-10 py-1 px-2 fs-7 rounded mb-2',
                {
                  'bg-success text-success': diff > 0,
                  'bg-danger text-danger': diff <= 0
                }
              )}
            >
              <span className="me-1">
                {diff > 0 ? <ArrowUp /> : <ArrowDown />}
              </span>
              {diff}%
            </div>
          </OverlayTrigger>
        </div>
      </Col>
    </Row>
  );
};

export default CompareScans;
