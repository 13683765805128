import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



const Title = () => {
  const { t } = useTranslation()
  return (
    <Col xs={12}>
      <h2 className='fw-bold fs-1 mt-5 d-none d-lg-block'>
        {t('header.title')}
      </h2>
      <h2 className='fw-bold fs-2 mt-4 d-lg-none'>
        {t('header.title')}
      </h2>
        <div className='text-gray mb-lg-3'> {t('header.description')}</div>
    </Col>
  );
};

export default Title;
